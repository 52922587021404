import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";
import { SelectInput } from "components/formik/SelectField";
import { Button } from "components/Button";
import { TextInput } from "components/formik/TextField";
import { removeVoidKeys } from "utils/removeVoidKeys";

const PROVIDERS_FILTER_DATA = gql`
  query ProvidersFilterData {
    states {
      id
      name
    }
  }
`;

interface Data {
  states: StateModel[];
}

interface StateModel {
  id: string;
  name: string;
}

export interface FilterModel extends Record<string, any> {
  searchTerm?: string;
  stateLicensureId?: string;
}

export const defaultFilter: FilterModel = {
  searchTerm: "",
};

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value, onChange, isLoading = false } = props;
  const { data, loading } = useQuery<Data>(PROVIDERS_FILTER_DATA);

  const stateOptions = data?.states.map(asOption) || [];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values) => onChange(removeVoidKeys<FilterModel>(values))}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="searchTerm" icon="search" label="Search Term">
          <div className="w-48">
            <TextInput
              name="searchTerm"
              placeholder="Search..."
              icon="search"
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="stateLicensureId"
          icon="filter"
          label="State Licensure"
        >
          <div className="w-48">
            <SelectInput
              isLoading={loading}
              isClearable
              name="stateLicensureId"
              options={stateOptions}
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="teal"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

function asOption<T extends { id: string; name: string }>(item: T): Option {
  return {
    value: item.id,
    label: item.name,
  };
}
