import { FC } from "react";
import { CircleIcon } from "components/CircleIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface CircleIconHeaderProps {
  icon: IconProp;
  color?: AppColor;
}

export const CircleIconHeader: FC<CircleIconHeaderProps> = (props) => {
  const { icon, color = "accent-green", children } = props;
  return (
    <div className="_CircleIconHeader md:px-4 flex items-center">
      <CircleIcon icon={icon} color={color} />
      <h2 className="sm:text-3xl ml-3 text-2xl font-black text-gray-800">
        {children}
      </h2>
    </div>
  );
};
