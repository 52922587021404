import { FC, useCallback, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/Button";
import { ScreenTitle } from "context/ScreenTitle";
import toast from "react-hot-toast";
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { useLogStream } from "context/LogStreamContext";

const LIST_HEALTH_PLANS = gql`
  query ListHealthPlans {
    healthPlans {
      cursor
      endOfList
      items {
        id
        name
        evicoreId
        ovicareId
        createdBySync
      }
    }
  }
`;

interface Data {
  healthPlans: Paginated<HealthPlanModel>;
}

interface HealthPlanModel {
  id: string;
  name: string;
  evicoreId: string;
  ovicareId: null | string;
  createdBySync: boolean;
}

const SYNC_OVICARE_HEALTH_PLANS = gql`
  mutation SyncOvicareHealthPlans {
    syncOvicareHealthPlans {
      success
      error {
        key
        message
      }
    }
  }
`;

interface MutationData {
  syncOvicareHealthPlans: {
    success: boolean;
    error: {
      key: string;
      message: string;
    } | null;
  };
}

interface HealthPlansScreenProps { }

export const HealthPlansScreen: FC<HealthPlansScreenProps> = (props) => {
  const [syncError, setSyncError] = useState<InputError | null>(null);
  const { setLogStreamId } = useLogStream();

  const { data, loading, error, refetch } = useQuery<Data>(LIST_HEALTH_PLANS);

  const [syncOvicareHealthPlansMut, { loading: isQueueingSync }] = useMutation<MutationData>(SYNC_OVICARE_HEALTH_PLANS);

  const syncOvicareHealthPlans = useCallback(async () => {
    setSyncError(null);
    setLogStreamId("health_plan_sync");
    try {
      const { data } = await syncOvicareHealthPlansMut();
      if (data?.syncOvicareHealthPlans.error) {
        setSyncError(data.syncOvicareHealthPlans.error);
        toast.error("Health plan sync failed.");
      } else if (data?.syncOvicareHealthPlans.success) {
        toast.success("Health plan sync successful.");
        return refetch();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
      return e;
    }
  }, [setLogStreamId, syncOvicareHealthPlansMut, refetch]);

  return (
    <>
      <ScreenTitle title="Health Plans" />
      <div className="_HealthPlansScreen sm:rounded-md ">
        <div className="sm:p-6 px-4 py-6 bg-white">

          <div className="flex items-center justify-between pb-4">
            <div>
              <h2
                id="health_plans_heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Health Plans
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Manage system health plans
              </p>
            </div>
            <div className="pt-6 pl-6">
              <Button
                onClick={syncOvicareHealthPlans}
                color="teal"
                kind="primary"
                isLoading={isQueueingSync}
                disabled={isQueueingSync}
              >
                Run Health Plan Sync
              </Button>
            </div>
          </div>
          {syncError ? (
            <p className="bg-red-50 p-2 text-red-700 border border-red-300 rounded">
              {syncError.message}
            </p>
          ) : null}

          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.healthPlans ? (
            <p>Failed to load.</p>
          ) : data.healthPlans.items.length === 0 ? (
            <NoResults icon="user-md" text="No matching health plans" />
          ) : (
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH>Name</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.healthPlans.items.map((hp) => (
                    <tr key={hp.id}>
                      <TD>
                        {hp.name}
                        {
                          hp.ovicareId ? (
                            <p className="pt-1 text-xs italic text-gray-400">ovicare_id: {hp.ovicareId}</p>
                          ) : null
                        }
                      </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};
