import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "components/FAIcon";

const colorClassNames: Record<AppColor, string> = {
  gray: "bg-gray-200 text-gray-700",
  red: "bg-red-200 text-red-700",
  orange: "bg-orange-200 text-orange-700",
  yellow: "bg-yellow-200 text-yellow-700",
  green: "bg-green-200 text-green-700",
  blue: "bg-blue-200 text-blue-700",
  indigo: "bg-indigo-200 text-indigo-700",
  purple: "bg-purple-200 text-purple-700",
  pink: "bg-pink-200 text-pink-700",
  gold: "bg-gold-200 text-gold-700",
  navy: "bg-navy-200 text-navy-700",
  teal: "bg-teal-50 text-teal-700",
  mint: "bg-mint-200 text-mint-700",
  "accent-green": "bg-accent-green-100 text-accent-green-700",
  "dark-teal": "bg-dark-teal-50 text-dark-teal-700",
};

interface CircleIconProps {
  icon: IconProp;
  size?: number;
  iconSize?: number;
  color?: AppColor;
}

export const CircleIcon: FC<CircleIconProps> = ({
  icon,
  size,
  iconSize,
  color = "accent-green",
}) => {
  return (
    <div
      className={`${colorClassNames[color]} h-10 w-10 flex justify-center items-center rounded-full`}
      style={!!size ? { width: size, height: size } : {}}
    >
      {!!iconSize ? (
        <span style={{ fontSize: iconSize }}>
          <FAIcon icon={icon} />
        </span>
      ) : (
        <FAIcon icon={icon} />
      )}
    </div>
  );
};
