import { FC, useState, useCallback } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CaseLookupParams, ExternalCase } from "./model";
import { Button } from "components/Button";
import { BlockSpinner } from "components/BlockSpinner";
import { DebugJSONDrawer } from "./DebugJSONDrawer";
import { DetailItem, DetailList } from "components/DetailList";
import { translateLOR } from "utils/kentuckyWording";
import { CircleIcon } from "components/CircleIcon";
import { mmDdYyyy } from "utils/dateFormatters";
import { ActivityEligibility } from "components/ActivityEligibility";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { P2PAppointmentList } from "components/P2PAppointmentList";

const EXTERNAL_CASE = gql`
  query FetchExternalCase($input: ExternalCaseLookupInput!) {
    levelsOfReview {
      value
      label
    }
    externalCase(input: $input) {
      error
      message
      json
      externalCase {
        isP2pEligible
        episodeId
        memberId
        memberName
        memberFirstName
        memberLastName
        memberDob
        systemName
        modality {
          id
          name
        }
        caseNumber
        caseSkillName
        caseSkills {
          id
          name
        }
        message
        healthPlanName
        healthPlan {
          id
          name
        }
        levelOfReview
        p2pValidUntilDate
        allowedProviderDomainIds
        excludedProviderDomainIds
        sameSpecialtyMatchRequired
        orderingPhysicianSpecialty {
          id
          name
        }
        sameStateLicensureRequired
        sameStateLicensureState {
          id
          name
          abbreviation
        }
        insurancePlanCode
        json
      }
    }
  }
`;

interface Data {
  levelsOfReview: {
    value: string;
    label: string;
  }[];
  externalCase: {
    error?: string;
    message?: string;
    externalCase?: ExternalCase;
    json?: string;
  };
}

interface Variables {
  input: CaseLookupParams;
}

const CREATE_DRAFT_APPEAL_REQUEST = gql`
  mutation CreateDraftAppealRequest($input: CreateDraftAppealRequestInput!) {
    createDraftAppealRequest(input: $input) {
      errors {
        key
        message
      }
      appealRequest {
        id
      }
    }
  }
`;

interface MutationData {
  createDraftAppealRequest: {
    errors?: InputError[];
    appealRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: CreateDraftAppealRequestInput;
}

interface CreateDraftAppealRequestInput {
  caseProfile: CaseProfileInput;
}

interface CaseProfileInput {
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  memberId: string;
  modalityId: string;
  healthPlanId: string;
  memberStateId: string;
  caseNumber: string;
  episodeId: string;
  levelOfReview: string;
  caseSystem: string;
  p2pValidUntilDate?: string | void;
  lookupMessage: string;
  allowedProviderDomainIds?: string[] | void;
  excludedProviderDomainIds?: string[] | void;
  pathwaySkillIds?: string[] | void;
  sameSpecialtyMatchRequired?: boolean;
  sameStateLicensureRequired?: boolean;
  orderingPhysicianSpecialtyId?: string | void;
  sameStateLicensureStateId?: string | void;
  insurancePlanCode?: string;
  evicoreApiResponse: string;
}

const messageBoxClassNames: Record<"red" | "teal", string> = {
  red: "bg-red-50 border-red-300 text-red-900",
  teal: "bg-teal-50 border-teal-300 text-teal-900",
};

interface ExternalCaseViewEditProps {
  caseLookupParams: CaseLookupParams;
  onClear(): void;
  onAppealDraftCreated(appeadRequestId: string): void;
}

export const ExternalCaseViewEdit: FC<ExternalCaseViewEditProps> = (props) => {
  const { caseLookupParams, onClear, onAppealDraftCreated } = props;

  const [debuggerOpen, setDebuggerOpen] = useState(false);
  // const [mode, setMode] = useState<"VIEW" | "EDIT">("VIEW");

  const { data, loading, error } = useQuery<Data, Variables>(EXTERNAL_CASE, {
    variables: { input: caseLookupParams },
    fetchPolicy: "network-only",
  });

  const externalCase = data?.externalCase.externalCase;

  const [createAppealDraft] = useMutation<MutationData, MutationVariables>(
    CREATE_DRAFT_APPEAL_REQUEST
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<{ errors?: InputError[] | undefined }>({
    errors: undefined,
  });

  const onSubmit = useCallback(() => {
    setIsSubmitting(true);
    const input = {
      caseProfile: caseProfileForExternalCase(externalCase!),
    };
    return createAppealDraft({ variables: { input } }).then((resp) => {
      if (resp.data?.createDraftAppealRequest.errors) {
        setStatus({ errors: resp.data.createDraftAppealRequest.errors });
      } else if (resp.data?.createDraftAppealRequest.appealRequest) {
        // it worked...
        return onAppealDraftCreated(
          resp.data.createDraftAppealRequest.appealRequest.id
        );
      }
    });
  }, [externalCase, createAppealDraft, onAppealDraftCreated]);

  const reviewLevels = data?.levelsOfReview || [];

  const reviewLevel = reviewLevels.find(
    (rl) => rl.value === data?.externalCase.externalCase?.levelOfReview
  );

  const reviewLevelLabel = translateLOR(
    reviewLevel?.label,
    data?.externalCase.externalCase?.insurancePlanCode,
    data?.externalCase.externalCase?.sameStateLicensureState?.name
  );

  const nonTranslatedMessageOrError =
    data?.externalCase.externalCase?.message ||
    data?.externalCase.message ||
    data?.externalCase.error;

  const lookupMessage = translateLOR(
    nonTranslatedMessageOrError,
    data?.externalCase.externalCase?.insurancePlanCode,
    data?.externalCase.externalCase?.sameStateLicensureState?.name
  );

  const noticeColor = data?.externalCase.externalCase?.isP2pEligible
    ? "teal"
    : "red";

  return (
    <>
      <DebugJSONDrawer
        isOpen={debuggerOpen}
        onClose={() => setDebuggerOpen(false)}
        json={data?.externalCase.json}
      />
      <div className="ExternalCaseViewEdit">
        {loading ? (
          <div className="flex items-center justify-center px-6 py-32">
            <BlockSpinner />
          </div>
        ) : error || !data?.externalCase ? (
          <p>Failed to load.</p>
        ) : (
          <div>
            <div className="_ViewExternalCaseCard">
              <div className="_Message pt-4">
                {lookupMessage ? (
                  <div
                    id="message-container"
                    className="flex justify-around max-w-xl px-4 py-3 mx-auto"
                  >
                    <div
                      id="message-box"
                      className={`p-2 flex items-center rounded-lg border ${messageBoxClassNames[noticeColor]}`}
                    >
                      <div className="w-8 h-8">
                        <CircleIcon
                          icon="exclamation"
                          size={32}
                          color={noticeColor}
                        />
                      </div>
                      <p className="ml-4 text-sm">{lookupMessage}</p>
                    </div>
                  </div>
                ) : null}
              </div>

              {status.errors ? (
                <div className="py-2">
                  <FormStatusErrors status={status} />
                </div>
              ) : null}

              {data.externalCase.externalCase ? (
                <div className="grid grid-cols-2 gap-3">
                  <div className="p-4 border rounded-lg shadow-md">
                    <h4 className="px-2 pt-1 pb-5 text-sm font-bold text-gray-600">
                      Member Information
                    </h4>
                    <DetailList>
                      <DetailItem label="Name">
                        {data.externalCase.externalCase.memberFirstName}{" "}
                        {data.externalCase.externalCase.memberLastName}
                      </DetailItem>
                      <DetailItem label="DOB">
                        {data.externalCase.externalCase.memberDob
                          ? mmDdYyyy(data.externalCase.externalCase.memberDob)
                          : "-"}
                      </DetailItem>
                      <DetailItem label="State">
                        {
                          data.externalCase.externalCase
                            ?.sameStateLicensureState?.name
                        }
                      </DetailItem>
                      <DetailItem label="Health Plan">
                        {data.externalCase.externalCase.healthPlanName}
                      </DetailItem>
                      <DetailItem label="Member ID">
                        {data.externalCase.externalCase.memberId || "-"}
                      </DetailItem>
                    </DetailList>
                  </div>
                  <div className="p-4 border rounded-lg shadow-md">
                    <h4 className="px-2 pt-1 pb-3 text-sm font-bold text-gray-600">
                      P2P Appointments
                    </h4>
                    <P2PAppointmentList caseNumber={data.externalCase.externalCase.caseNumber} />

                    <h4 className="px-2 py-5 pt-1 text-sm font-bold text-gray-600">
                      Case Information
                    </h4>
                    <div className="flex justify-center pb-4">
                      <div>
                        {/* TODO: */}
                        <ActivityEligibility allowed label="No open post-decision requests" />
                      </div>
                    </div>
                    <DetailList>
                      <DetailItem label="Case Ref #">
                        {data.externalCase.externalCase.caseNumber}
                      </DetailItem>
                      <DetailItem label="Episode ID">
                        {data.externalCase.externalCase.episodeId}
                      </DetailItem>
                      <DetailItem label="Can Appeal Until">
                        {data.externalCase.externalCase.p2pValidUntilDate
                          ? mmDdYyyy(
                            data.externalCase.externalCase.p2pValidUntilDate
                          )
                          : "-"}
                      </DetailItem>
                      <DetailItem label="Modality">
                        {data.externalCase.externalCase.modality?.name}
                      </DetailItem>
                      <DetailItem label="Level of Review">
                        {reviewLevelLabel || "-"}
                      </DetailItem>
                      <DetailItem label="Case System">
                        {data.externalCase.externalCase.systemName}
                      </DetailItem>
                    </DetailList>
                  </div>
                </div>
              ) : null}
              <div className="p-2 text-center">
                <Button
                  type="button"
                  onClick={() => setDebuggerOpen(true)}
                  kind="tertiary"
                  color="purple"
                  size="xs"
                >
                  Raw JSON Response
                </Button>
              </div>
            </div>
            <div className="_Actions flex items-center justify-center gap-3 px-2 py-4 mt-3">
              <Button
                type="button"
                onClick={onClear}
                kind="primary"
                size="lg"
                disabled={isSubmitting}
              >
                Reset Lookup
              </Button>
              <Button
                type="button"
                onClick={onSubmit}
                kind="primary"
                color="teal"
                size="lg"
                disabled={isSubmitting || !data.externalCase.externalCase}
                isLoading={isSubmitting}
              >
                Continue
              </Button>
            </div>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        )}
      </div>
    </>
  );
};

function caseProfileForExternalCase(
  externalCase: ExternalCase
): CaseProfileInput {
  return {
    memberFirstName: externalCase.memberFirstName,
    memberLastName: externalCase.memberLastName,
    memberDob: externalCase.memberDob!,
    memberId: externalCase.memberId!,
    modalityId: externalCase.modality?.id!,
    healthPlanId: externalCase.healthPlan?.id!,
    memberStateId: externalCase.sameStateLicensureState?.id!,
    caseNumber: externalCase.caseNumber,
    episodeId: externalCase.episodeId!,
    levelOfReview: externalCase.levelOfReview!,
    caseSystem: externalCase.systemName!,
    p2pValidUntilDate: externalCase.p2pValidUntilDate,
    lookupMessage: externalCase.message!,
    allowedProviderDomainIds: externalCase.allowedProviderDomainIds,
    excludedProviderDomainIds: externalCase.excludedProviderDomainIds,
    pathwaySkillIds: externalCase.caseSkills?.map((s) => s.id),
    sameSpecialtyMatchRequired: externalCase.sameSpecialtyMatchRequired,
    sameStateLicensureRequired: externalCase.sameStateLicensureRequired,
    orderingPhysicianSpecialtyId: externalCase.orderingPhysicianSpecialty?.id,
    sameStateLicensureStateId: externalCase.sameStateLicensureState?.id,
    evicoreApiResponse: externalCase.json,
  };
}
