import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Modal } from "components/Modal";
import { Icon } from "components/Icon";
import { FAIcon } from "components/FAIcon";

/**
 * TileButton.
 */

// interface TileButtonProps {
//   onClick?(): void;
//   className?: string;
// }

// const TileButton: FC<TileButtonProps> = (props) => {
//   const { onClick, children, className = "" } = props;

//   return (
//     <button
//       type="button"
//       onClick={onClick}
//       className={`_TileButton bg-white border-2 border-gray-400 focus:outline-none focus:shadow-outline hover:border-navy-400 hover:shadow-lg py-8 rounded-lg shadow text-center text-gray-700 hover:text-navy-700 ${className}`}
//     >
//       <div className="px-2">{children}</div>
//     </button>
//   );
// };

/**
 * TileLink.
 */
interface TileLinkProps {
  to: string;
  onClick?(): void;
  className?: string;
}

const TileLink: FC<TileLinkProps> = (props) => {
  const { to, onClick, className = "", children } = props;

  return (
    <Link
      to={to}
      className={`_TileButton bg-white border-2 border-gray-400 focus:outline-none focus:shadow-outline hover:border-navy-400 hover:shadow-lg py-8 rounded-lg shadow text-center text-gray-700 hover:text-navy-700 ${className}`}
      onClick={onClick}
    >
      <div className="px-2">{children}</div>
    </Link>
  );
};

/**
 * NewAppealFromChannelModal.
 */

interface NewAppealFromChannelModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const NewAppealFromChannelModal: FC<NewAppealFromChannelModalProps> = (
  props
) => {
  const { isOpen, onClose } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="max-w-3xl rounded-lg"
    >
      <div className="NewThingModal border-navy-600 bg-gray-100 border-t-4 rounded-lg">
        <div className="flex items-center justify-between px-6 py-4">
          <div className="flex items-center">
            <div className="bg-yellow-50 border-gold-500 p-2 border rounded-full">
              {/* <CircleIcon icon="plus" /> */}
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="text-gold-600"
                  d="M16 29.3334C23.3638 29.3334 29.3333 23.3638 29.3333 16C29.3333 8.63622 23.3638 2.66669 16 2.66669C8.63616 2.66669 2.66663 8.63622 2.66663 16C2.66663 23.3638 8.63616 29.3334 16 29.3334Z"
                  fill="currentColor"
                />
                <path
                  className="text-white"
                  d="M17.3333 14.6667H22.6667C23.0203 14.6667 23.3594 14.8071 23.6095 15.0572C23.8595 15.3072 24 15.6464 24 16C24 16.3536 23.8595 16.6928 23.6095 16.9428C23.3594 17.1929 23.0203 17.3333 22.6667 17.3333H17.3333V22.6667C17.3333 23.0203 17.1929 23.3594 16.9428 23.6095C16.6928 23.8595 16.3536 24 16 24C15.6464 24 15.3072 23.8595 15.0572 23.6095C14.8071 23.3594 14.6667 23.0203 14.6667 22.6667V17.3333H9.33333C8.97971 17.3333 8.64057 17.1929 8.39052 16.9428C8.14048 16.6928 8 16.3536 8 16C8 15.6464 8.14048 15.3072 8.39052 15.0572C8.64057 14.8071 8.97971 14.6667 9.33333 14.6667H14.6667V9.33333C14.6667 8.97971 14.8071 8.64057 15.0572 8.39052C15.3072 8.14048 15.6464 8 16 8C16.3536 8 16.6928 8.14048 16.9428 8.39052C17.1929 8.64057 17.3333 8.97971 17.3333 9.33333V14.6667Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="ml-4">
              <h4 className="text-2xl font-bold text-gray-700">
                Draft New Post-Decision Request
              </h4>
            </div>
          </div>
          <button
            className="hover:text-gray-700 text-xl text-gray-500"
            onClick={onClose}
          >
            <FAIcon icon="times" />
          </button>
        </div>
        <div className="lg:pb-16 max-w-2xl px-4 pb-8 mx-auto">
          <div className="pt-4 pb-6">
            <p className="text-lg font-semibold text-gray-700">
              Select Request Channel:
            </p>
          </div>
          <div className="md:flex items-center justify-around -mx-2">
            <div className="flex flex-col flex-grow px-2">
              <TileLink to="/ip/drafts/new" onClick={onClose}>
                <div className="flex flex-col items-center justify-between h-32 py-2">
                  <Icon icon="at" size={64} />
                  <p className="font-semibold leading-tight">Email</p>
                </div>
              </TileLink>
              <TileLink to="/ip/drafts/new" className="mt-4" onClick={onClose}>
                <div className="flex flex-col items-center justify-between h-32 py-2">
                  <Icon icon="chat-group" size={64} />
                  <p className="font-semibold leading-tight">Chat</p>
                </div>
              </TileLink>
            </div>

            <div className="flex flex-col flex-grow px-2">
              <TileLink to="/ip/drafts/new" onClick={onClose}>
                <div className="flex flex-col items-center justify-between h-32 py-2">
                  <Icon icon="mail" size={64} />
                  <p className="font-semibold leading-tight">Mail</p>
                </div>
              </TileLink>
              <TileLink to="/ip/drafts/new" className="mt-4" onClick={onClose}>
                <div className="flex flex-col items-center justify-between h-32 py-2">
                  <Icon icon="fax" size={64} />
                  <p className="font-semibold leading-tight">Fax</p>
                </div>
              </TileLink>
            </div>
            <div className="bg-gray-400 h-0.5 w-48 md:w-0.5 md:h-48 mx-auto md:mx-3 my-3 md:my-0" />
            <div className="flex flex-col flex-grow px-2">
              <TileLink to="/ip/drafts/new" onClick={onClose}>
                <div className="flex flex-col items-center justify-between h-32 py-2">
                  <Icon icon="phone-incoming" size={64} />
                  <p className="font-semibold leading-tight">Phone Call</p>
                </div>
              </TileLink>
              {/* <TileLink
                to="/o/patients?open=new"
                className="mt-4"
                onClick={onClose}
              >
                <div className="flex flex-col items-center justify-between h-32 py-2">
                  <Icon icon="user-add" size={64} />
                  <p className="font-semibold leading-tight">Patient</p>
                </div>
              </TileLink> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
