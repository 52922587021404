import { FC, useCallback, useState } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";
import { CallOutcome, LogVerbalNotificationForm, FormValues as Step1FormValues } from "./LogVerbalNotificationForm";
import { NavigationSteps } from "components/NavigationSteps";
import { ConfirmVerbalNotificationForm, FormValues } from "./ConfirmVerbalNotificationForm";
import { Button } from "components/Button";
import { FAIcon } from "components/FAIcon";

interface LogVerbalNotificationDrawerProps {
  appealRequestId: string | null;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const LogVerbalNotificationDrawer: FC<
  LogVerbalNotificationDrawerProps
> = (props) => {
  const { isOpen, onClose, appealRequestId, onSuccess: onSuccessProp } = props;

  const [step1Values, setStep1Values] = useState<Step1FormValues>({ whoCalling: "", speakingWith: "", attemptOutcome: "", otherOutcome: "" });
  const [currentStep, setCurrentStep] = useState<0 | 1>(0);

  const onSuccess = useCallback(() => {
    onSuccessProp();
    onClose();
  }, [onSuccessProp, onClose]);

  const onStep1Submit = useCallback((values: Step1FormValues) => {
    setStep1Values(values);
    setCurrentStep(1);
  }, [setStep1Values, setCurrentStep]);

  return (
    <Drawer
      width="4xl"
      className="_LogVerbalNotificationDrawer"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <DrawerHeader
        icon="pencil-alt"
        title="Log Verbal Notification"
        onClose={onClose}
      />
      <div className="p-4">
        <div className="max-w-sm mx-auto">
          <NavigationSteps
            steps={[{ label: "Form", icon: "clipboardList" }, { label: "Confirm", icon: "checkCircle" }]}
            currentStep={currentStep}
          />
        </div>
        {
          currentStep === 1 && step1Values ? (
            <div className="max-w-md pt-6 mx-auto">
              <div className="text-center">
                <Button type="button" kind="tertiary" color="mint" onClick={() => setCurrentStep(0)}>
                  <FAIcon icon="arrow-left" className="mr-2" />Back
                </Button>
              </div>
              <div className="p-3 py-6 mt-4 bg-white border rounded-lg shadow-lg">
                <h3 className="px-3 pb-6 text-3xl font-bold">Confirm</h3>
                <ConfirmVerbalNotificationForm
                  appealRequestId={appealRequestId!}
                  initialValues={confirmValues(step1Values)}
                  onSuccess={onSuccess}
                />
              </div>
            </div>
          ) : (
            <div className="pt-6">
              <LogVerbalNotificationForm
                initialValues={step1Values}
                onSubmit={onStep1Submit}
              />
            </div>
          )
        }
      </div>
    </Drawer>
  );
};

type TemplateSet = { journalNote: string, historyMessage: string };

const templates: Record<CallOutcome, TemplateSet> = {
  successful: {
    journalNote: "Verbal notification to {{whoCalling}} was successful",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: Success",
  },
  answering_machine: {
    journalNote: "Verbal notification to {{whoCalling}} attempted but unsuccessful: Party not reached.",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: Party not reached.",
  },
  no_answer: {
    journalNote: "Attempted {{whoCalling}} verbal notification, but call went unanswered.",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}",
  },
  busy_signal: {
    journalNote: "Verbal notification to {{whoCalling}} attempted but unsuccessful: Busy signal.",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: Busy Signal",
  },
  party_not_reached: {
    journalNote: "Verbal notification to {{whoCalling}} attempted but unsuccessful: Party Not Reached (Spoke with someone other than intended party)",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: Party Not Reached (Spoke with someone other than intended party)",
  },
  fax_signal: {
    journalNote: "Verbal notification to {{whoCalling}} attempted but unsuccessful: Fax signal.",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: Fax Signal",
  },
  outgoing_signal: {
    journalNote: "Verbal notification to {{whoCalling}} attempted but unsuccessful: Outgoing message only, no voicemail capability.",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: Outgoing message only, no voicemail capability.",
  },
  wrong_number: {
    journalNote: "Verbal notification to {{whoCalling}} attempted but unsuccessful: Invalid number.",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: Invalid number.",
  },
  other: {
    journalNote: "Verbal notification to {{whoCalling}} attempted but unsuccessful: {{otherOutcome}}",
    historyMessage: "Verbal Notification Attempt to {{whoCalling}}: {{otherOutcome}}",
  },
}

function confirmValues(values: Step1FormValues): FormValues {
  const { attemptOutcome } = values;

  return {
    historyMessage: renderTemplate(templates[attemptOutcome].historyMessage, values),
    journalNote: renderTemplate(templates[attemptOutcome].journalNote, values)
  }
}

function renderTemplate(template: string, data: Record<keyof Step1FormValues, string>): string {
  return template.replace(/\{\{(\w+)\}\}/g, function (match, key) {
    return key in data ? data[key] : match;
  });
}
