import { FC, useCallback, useState } from "react";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from "components/Button";
import { ScreenTitle } from "context/ScreenTitle";
import toast from 'react-hot-toast';
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { FilterPanel, FilterModel, defaultFilter } from "./FilterPanel";
import { useLogStream } from "context/LogStreamContext";

const LIST_PROVIDERS = gql`
  query ListProviders($first: Int, $after: UUID4, $filter: ListProvidersFilter) {
    providers(first: $first, after: $after, filter: $filter) {
      endOfList
      cursor
      items {
        id
        firstName
        lastName
        email
        domainId
        imageoneId
        primarySpecialty
        stateLicensures {
          id
          abbreviation
        }
      }
    }
  }
`;

interface Data {
  providers: Paginated<ProviderModel>;
}

interface ProviderModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  domainId: string;
  imageoneId: null | string;
  primarySpecialty: string | null;
  stateLicensures: { id: string, abbreviation: string }[];
}

const SYNC_OVICARE_PROVIDERS = gql`
  mutation SyncOvicareProviders {
    syncOvicareProviders {
      success
      error {
        key
        message
      }
    }
  }
`;

interface MutationData {
  syncOvicareProviders: {
    success: boolean;
    error?: InputError;
  }
}

/**
 * ProvidersScreen.
 */

interface ProvidersScreenProps { };

export const ProvidersScreen: FC<ProvidersScreenProps> = props => {

  const [syncError, setSyncError] = useState<InputError | null>(null);
  const [filter, setFilter] = useState<FilterModel>(defaultFilter);
  const { setLogStreamId } = useLogStream();

  const { data, loading, error, refetch } = useQuery<Data>(LIST_PROVIDERS, { variables: { filter } });

  const [syncOvicareProvidersMut, { loading: isQueueingSync }] = useMutation<MutationData>(SYNC_OVICARE_PROVIDERS);

  const syncOvicareProviders = useCallback(async () => {
    setSyncError(null);
    setLogStreamId("provider_sync");
    try {
      const { data } = await syncOvicareProvidersMut();
      if (data?.syncOvicareProviders.error) {
        setSyncError(data.syncOvicareProviders.error);
      } else if (data?.syncOvicareProviders.success) {
        // it worked...
        toast.success("Provider sync succeeded.")
        return refetch();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.")
      return e;
    }
  }, [setLogStreamId, syncOvicareProvidersMut, refetch]);

  return (
    <>
      <ScreenTitle title="Providers" />
      <div className="_ProvidersScreen sm:rounded-md sm:overflow-hidden shadow">
        <div className="sm:p-6 px-4 py-6 bg-white">
          <div>
            <h2 id="providers_heading" className="text-lg font-medium leading-6 text-gray-900">
              Providers
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Manage system providers
            </p>

            <div className="pt-6">
              <div className="flex items-start justify-between pb-4">
                <div>
                  <FilterPanel
                    value={filter}
                    onChange={setFilter}
                  />
                </div>
                <div className="pt-6 pl-6">
                  <Button
                    onClick={syncOvicareProviders}
                    color="teal"
                    kind="primary"
                    isLoading={isQueueingSync}
                    disabled={isQueueingSync}
                  >
                    Run Provider Sync
                  </Button>
                </div>
              </div>
              {
                syncError ? (
                  <p className="bg-red-50 p-2 text-red-700 border border-red-300 rounded">{syncError.message}</p>
                ) : null
              }

              {
                loading ? (
                  <div className="p-8 text-center">
                    <Spinner />
                  </div>
                ) : error || !data?.providers ? (
                  <p>Failed to load.</p>
                ) : data.providers.items.length === 0 ? (
                  <NoResults icon="user-md" text="No matching providers" />
                ) : (
                  <TableContainer>
                    <Table className="text-left">
                      <thead>
                        <tr>
                          <TH>Name</TH>
                          <TH>Specialty</TH>
                          <TH>State Licenses</TH>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.providers.items.map(p => (
                            <tr key={p.id}>
                              <TD>
                                <p>{p.firstName} {p.lastName}</p>
                                <p className="text-xs text-gray-500">{p.email}</p>
                                <p className="text-xs text-gray-500">domain_id: {p.domainId}</p>
                                <p className="text-xs text-gray-500">imageone_id: {p.imageoneId || null}</p>
                              </TD>
                              <TD>{p.primarySpecialty}</TD>
                              <TD>{p.stateLicensures.map(s => s.abbreviation).join(", ")}</TD>
                            </tr>
                          ))
                        }
                      </tbody>

                    </Table>
                  </TableContainer>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
