import { FC } from "react";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { Button } from "components/Button";
import { useAuth } from "context/AuthContext";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface Data {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface Variables {
  email: string;
  password: string;
}

interface FormValues {
  email: string;
  password: string;
}

interface PasswordSignInFormProps {
  onSSOClick(): void;
}

export const PasswordSignInForm: FC<PasswordSignInFormProps> = (props) => {
  const { onSSOClick } = props;

  const { login: signIn } = useAuth();
  const [login] = useMutation<Data, Variables>(LOGIN);

  const onSubmit = (values: FormValues, formikActions: any) => {
    const { setStatus, setSubmitting } = formikActions;
    setStatus({ errors: null });
    login({ variables: values }).then(
      (resp) => {
        if (resp?.data?.login.errors) {
          setStatus({ errors: resp.data.login.errors });
        } else if (resp?.data?.login?.session) {
          // it worked
          const { token } = resp.data.login.session;
          // onSignIn(token);
          return signIn(token);
        }
        setSubmitting(false);
      },
      (rej) => {
        setStatus({
          errors: [{ key: "", message: "Something went wrong" }],
        });
        setSubmitting(false);
      }
    );
  };

  return (
    <>
      <div className="_PasswordSignInForm sm:rounded-2xl sm:px-10 border-mint-500 px-4 py-8 bg-white border-t-2 shadow-xl">
        <Formik<FormValues>
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string().trim().required("Required"),
            password: Yup.string().required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <TextField name="email" type="text" label="Email" autoFocus />

              <div className="mt-3">
                <TextField
                  name="password"
                  placeholder="••••••••••"
                  type="password"
                  label="Password"
                />
              </div>

              <div className="mt-8">
                <span className="block w-full rounded-md shadow-sm">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="teal"
                    className="justify-center w-full"
                  >
                    Sign in
                  </Button>
                </span>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="mt-4 text-sm">
        <p className="max-w mt-1 text-sm leading-5 text-center text-gray-600">
          Using SSO?{" "}
          <button
            type="button"
            onClick={onSSOClick}
            className="hover:text-accent-green-700 text-accent-green-500 inline-block ml-2 font-semibold"
          >
            Sign in with SSO
          </button>
        </p>
        {/* <p className="max-w mt-1 text-sm leading-5 text-center text-gray-600">
          <Link
            to="/auth/forgot_password"
            className="hover:text-blue-700 inline-block ml-2 font-semibold text-blue-500"
          >
            Forgot password?
          </Link>
        </p> */}
      </div>
    </>
  );
};
