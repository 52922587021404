import { FC, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import uniqBy from "lodash.uniqby";
import groupBy from "lodash.groupby";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalTextField } from "components/formik/TextField";
import { HorizontalDateMaskField } from "components/formik/DateMaskField";
import {
  HorizontalSelectField,
  StandardOption,
} from "components/formik/SelectField";
import { Button } from "components/Button";
import { localDateRegex, localDateToISO } from "utils/localDateToISO";
import { HealthPlanOption } from "./HealthPlanOption";
import {
  CaseLookupParams,
  FlatHealthPlanModel,
  HealthPlanModel,
  LineOfBusinessModel,
} from "./model";

const CASE_LOOKUP_FORM_DATA = gql`
  query CaseLookupFormData {
    healthPlans(first: 300) {
      items {
        id
        name
        healthPlanPrograms {
          id
          caseSystem
          caseSystemHealthPlanAlias
        }
      }
    }
    linesOfBusiness(first: 100) {
      items {
        id
        name
      }
    }
  }
`;

interface Data {
  healthPlans: Paginated<HealthPlanModel>;
  linesOfBusiness: Paginated<LineOfBusinessModel>;
}

interface FormValues extends CaseLookupParams { }

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    healthPlanId: Yup.string().required("Required"),
    lineOfBusinessId: Yup.string().required("Required"),
    caseNumber: Yup.string().required("Required"),
    memberDob: Yup.string().required("Required").matches(localDateRegex, {
      message: "Invalid date",
    }),
  })
  .required();

interface CaseLookupFormProps {
  onSubmit(caseLookupParams: CaseLookupParams): void;
}

export const CaseLookupForm: FC<CaseLookupFormProps> = (props) => {
  const { onSubmit: setCaseLookupParams } = props;

  const { data, loading } = useQuery<Data>(CASE_LOOKUP_FORM_DATA);

  const healthPlanOptions = flattenHealthPlans(data?.healthPlans.items || []);
  const lineOfBusinessOptions = data?.linesOfBusiness.items.map(
    lineOfBusinessAsOption
  ) || [];

  const onSubmit = useCallback(
    (values: FormValues, { setSubmitting }) => {
      const lookupParams: CaseLookupParams = {
        healthPlanId: values.healthPlanId,
        lineOfBusinessId: values.lineOfBusinessId,
        caseNumber: values.caseNumber,
        memberDob: localDateToISO(values.memberDob),
      };

      setCaseLookupParams(lookupParams);
      setSubmitting(false);
    },
    [setCaseLookupParams]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        caseNumber: "",
        memberDob: "",
        healthPlanId: "",
        lineOfBusinessId: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, status }) => (
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
          <FormStatusErrors status={status} />

          <div className="mt-3">
            <HorizontalTextField name="caseNumber" label="Case Number" />
          </div>

          <div className="mt-3">
            <HorizontalDateMaskField
              name="memberDob"
              label="Member Date of Birth"
            />
          </div>

          <div className="mt-3">
            <HorizontalSelectField
              isLoading={loading}
              name="lineOfBusinessId"
              label="Program"
              options={lineOfBusinessOptions}
            />
          </div>

          <div className="mt-3">
            <HorizontalSelectField
              isLoading={loading}
              name="healthPlanId"
              label="Health Plan"
              options={healthPlanOptions}
              getOptionValue={getHealthPlanOptionValue}
              getOptionLabel={getHealthPlanOptionLabel}
              components={{ Option: HealthPlanOption }}
            />
          </div>

          <div className="py-4 mt-3 text-right">
            <Button
              type="submit"
              color="teal"
              size="lg"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Lookup Case
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export function flattenHealthPlans(
  healthPlans: HealthPlanModel[]
): FlatHealthPlanModel[] {
  return healthPlans.flatMap((hp) => {
    // NB: Assumption: There are no health plans with 0 programs.
    const grouped = groupBy(
      hp.healthPlanPrograms,
      (hpp) => hpp.caseSystemHealthPlanAlias ?? hp.name
    );
    const mapped = Object.keys(grouped).map((displayName) => ({
      id: hp.id,
      displayName,
      canonicalName: hp.name,
      aliasName: displayName !== hp.name ? displayName : null,
      caseSystems: uniqBy(
        grouped[displayName].map((hpp) => hpp.caseSystem),
        (caseSystem) => caseSystem
      ),
    }));
    return mapped;
  });
}

function lineOfBusinessAsOption(lob: LineOfBusinessModel): StandardOption {
  return {
    value: lob.id,
    label: lob.name,
  };
}

export function getHealthPlanOptionValue(hp: FlatHealthPlanModel) {
  return hp.id;
}

export function getHealthPlanOptionLabel(hp: FlatHealthPlanModel) {
  return hp.displayName;
}
