import { FC } from "react";
import { Button } from "components/Button";
import { SelectInput, StandardOption } from "components/formik/SelectField"
// import { TextInput } from "components/formik/TextField";
import { gql, useQuery } from "@apollo/client";
import { ZERO_WIDTH_SPACE } from "constants/constants"
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";

const TAT_RULES_FILTER_DATA = gql`
  query TatRulesFilterData {
    healthPlans(first: 300) {
      items {
        id
        name
      }
    }
  }
`;

interface Data {
  healthPlans: Paginated<HealthPlanModel>;
}

interface HealthPlanModel {
  id: string;
  name: string;
}

export interface FilterModel {
  healthPlanId?: string;
}

export const defaultValue: FilterModel = {
  healthPlanId: "ALL",
};

interface FilterPanelProps {
  value?: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

const ALL_OPTION = { value: "ALL", label: "All" };

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value = defaultValue, onChange, isLoading = false } = props;

  const { data, loading } = useQuery<Data>(TAT_RULES_FILTER_DATA);

  const healthPlanOptions =
    [ALL_OPTION, ...(data?.healthPlans.items.map(healthPlanAsOption) || [])];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="healthPlanId" icon="filter" label="Health Plan" className="w-64">
          <SelectInput
            name="healthPlanId"
            options={healthPlanOptions}
            isLoading={loading}
            placeholder="Any"
            isClearable
          />
        </FilterField>

        {/* <FilterField htmlFor="caseNumber" icon="search" label="Case Number / Episode ID" className="flex-grow">
          <TextInput
            name="caseNumber"
            icon="search"
            placeholder="Case Number / Episode ID"
          />
        </FilterField> */}

        <FilterField label={ZERO_WIDTH_SPACE}>
          <Button
            type="submit"
            color="teal"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  )
}

function healthPlanAsOption(hp: HealthPlanModel): StandardOption {
  return {
    value: hp.id,
    label: hp.name,
  };
}
