import { FC, useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "components/Button";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { gql, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { HorizontalRadioGroupField } from "components/formik/RadioGroupField";
import { DatePickerInput } from "components/formik/DatePickerField";
import { HorizontalChannelGroupField } from "components/formik/ChannelSelectField";
import { FAIcon } from "components/FAIcon";
import { localDateToISO } from "utils/localDateToISO";
import { Spinner } from "components/Spinner";
import { HorizontalField } from "components/FieldStructure";
import { SingleCheckboxInput } from "components/formik/SingleCheckboxField";
import { TimeMaskInput } from "components/formik/TimeMaskField";
import { PlainSelectInput } from "components/formik/PlainSelectField";
import { HorizontalSelectField } from "components/formik/SelectField";
import { HorizontalTextAreaField } from "components/formik/TextAreaField";
import { HorizontalDateMaskField } from "components/formik/DateMaskField";

const APPEAL_REQUEST = gql`
  query GetAppealRequest($id: UUID4!) {
    appealRequest(id: $id) {
      id
      channel
      urgency
      episodeId
      requestedAt
      initiatedDateString: requestedAtString(format: "{0M}/{0D}/{YYYY}")
      initiatedTimeString: requestedAtString(format: "{0h12}:{m} {am}")
    }
    timeZoneNames
    me {
      id
      timeZoneName
    }
  }
`;

interface Data {
  appealRequest: {
    id: string;
    episodeId: null | string;
    channel?: string;
    urgency?: string;
    requestedAt?: string;
    initiatedDateString?: string;
    initiatedTimeString?: string;
  };
  timeZoneNames: string[];
  me: {
    id: string;
    timeZoneName: string;
  };
}

interface Variables {
  id: string;
}

const SUBMIT_INTAKE_QUESTIONNAIRE = gql`
  mutation SubmitIntakeQuestionnaire(
    $appealRequestId: UUID4!
    $input: IntakeQuestionnaireInput!
  ) {
    submitIntakeQuestionnaire(appealRequestId: $appealRequestId, input: $input) {
      errors {
        key
        message
      }
      appealRequest {
        id
      }
    }
  }
`;

interface MutationData {
  submitIntakeQuestionnaire: {
    errors?: InputError[];
    appealRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appealRequestId: string;
  input: IntakeQuestionnaireInput;
}

interface IntakeQuestionnaireInput {
  channel: string;
  isUrgent: boolean;
  initiatedDate?: string;
  initiatedTime?: string;
  initiatedTimeZone?: string;
  preService: boolean;
  serviceDate: string | null;
  postReviewType: string;
  requesterType: string;
  appealReason?: string;
  lateProcessing?: boolean;
  kqReason?: string;
  verbalClinicalInfo?: string;
}

interface FormValues {
  channel: string;
  urgency: string;
  initiatedDate: string;
  initiatedTime: string;
  initiatedTimeZone: string;
  postReviewType: string;
  prePostService: string;
  serviceDate: string;
  requesterType: string;
  appealReason: string;
  lateProcessing: boolean;
  kqReason: string;
  verbalClinicalInfo: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    channel: Yup.string().required("Required"),
    urgency: Yup.string().required("Required"),
    postReviewType: Yup.string().required("Required"),
    prePostService: Yup.string().required("Required"),
    requesterType: Yup.string().required("Required"),
  })
  .required();

const appealReasonOptions = [
  "Appeal processed by EviCore healthcare",
  "Appeal processed by Health Plan",
  "Decision is modified as a result of peer-to-peer consult",
  "Decision is modified at the direction of the health plan",
  "Decision is modified based on administrative review",
  "External appeal decision",
].map((value) => ({ value, label: value }));

/**
 * QuestionForm.
 */

interface QuestionsFormProps {
  appealRequestId: string;
  onBack(): void;
  onSuccess(): void;
}

export const QuestionsForm: FC<QuestionsFormProps> = (props) => {
  const { appealRequestId, onBack, onSuccess } = props;

  const { data, loading, error } = useQuery<Data, Variables>(APPEAL_REQUEST, {
    variables: { id: appealRequestId },
    fetchPolicy: "network-only",
  });

  const [doMutation] = useMutation<MutationData, MutationVariables>(
    SUBMIT_INTAKE_QUESTIONNAIRE
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      let input: IntakeQuestionnaireInput = {
        channel: capitalize(values.channel)!,
        isUrgent: values.urgency === "Urgent",
        initiatedDate: localDateToISO(values.initiatedDate),
        initiatedTime: values.initiatedTime,
        initiatedTimeZone: values.initiatedTimeZone,
        postReviewType: values.postReviewType,
        preService: values.prePostService !== "Post-Service",
        serviceDate: values.prePostService === "Post-Service" && values.serviceDate ? localDateToISO(values.serviceDate) : null,
        requesterType: values.requesterType,
        appealReason: values.appealReason,
        lateProcessing: values.lateProcessing,
        kqReason: values.kqReason,
        verbalClinicalInfo: values.verbalClinicalInfo,
      };

      try {
        const { data } = await doMutation({
          variables: { appealRequestId, input },
        });

        if (data?.submitIntakeQuestionnaire.errors) {
          setStatus({ errors: data.submitIntakeQuestionnaire.errors });
        } else if (data?.submitIntakeQuestionnaire.appealRequest) {
          // it worked...
          toast.success("Success!");
          return onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [appealRequestId, onSuccess, doMutation]
  );

  return (
    <div className="_QuestionsForm">
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !data?.appealRequest ? (
        <p>Failed to load</p>
      ) : (
        <Formik<FormValues>
          initialValues={{
            channel: data.appealRequest.channel?.toLocaleLowerCase() || "",
            urgency: data.appealRequest.urgency || "",
            initiatedDate: data.appealRequest.initiatedDateString || "",
            initiatedTime: data.appealRequest.initiatedTimeString || "",
            initiatedTimeZone: data.me.timeZoneName,
            postReviewType: "",
            prePostService: "",
            serviceDate: "",
            requesterType: "",
            appealReason: "",
            lateProcessing: false,
            kqReason: "",
            verbalClinicalInfo: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, status, values }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <div className="mt-3">
                <HorizontalChannelGroupField
                  name="channel"
                  label="Channel"
                  options={["phone", "mail", "fax", "email", "web", "other"]}
                />
              </div>

              <div className="mt-3">
                <HorizontalRadioGroupField
                  name="urgency"
                  label="Urgency"
                  options={[
                    { value: "Standard", label: "Standard" },
                    { value: "Urgent", label: "Urgent / Expedited" },
                  ]}
                />
              </div>

              <div className="mt-3">
                <HorizontalRadioGroupField
                  name="postReviewType"
                  label="Post-Decision Review Type"
                  options={[
                    { value: "Appeal", label: "Appeal" },
                    { value: "Reconsideration", label: "Reconsideration" },
                  ]}
                />
              </div>

              <div className="mt-3">
                <HorizontalRadioGroupField
                  name="prePostService"
                  label="Pre/Post Service?"
                  options={[
                    { value: "Pre-Service", label: "Pre-Service" },
                    { value: "Post-Service", label: "Post-Service" },
                  ]}
                />
              </div>

              {/* TODO: Should this only be visible if "Post-Service"? */}
              {values.prePostService === "Post-Service" ? (
                <div className="mt-3">
                  <HorizontalDateMaskField
                    name="serviceDate"
                    label="Service Date"
                  />
                </div>
              ) : null}

              <div className="mt-3">
                <HorizontalSelectField
                  name="requesterType"
                  label="Requestor Type"
                  options={[
                    { value: "Member", label: "Member" },
                    { value: "Provider", label: "Provider" },
                    { value: "Site", label: "Site" },
                    { value: "Health Plan", label: "Health Plan" },
                    { value: "Member Delegate", label: "Member Delegate" },
                    { value: "Non-Par Provider", label: "Non-Par Provider" },
                  ]}
                />
              </div>

              <div className="mt-3">
                <HorizontalField label="Initiated At">
                  <div className="grid w-full max-w-md grid-cols-2 gap-2">
                    <div>
                      <DatePickerInput
                        name="initiatedDate"
                        icon="calendar-alt"
                      />
                    </div>
                    <div>
                      <TimeMaskInput
                        name="initiatedTime"
                        icon={["far", "clock"]}
                      />
                    </div>
                    <div className="col-span-2">
                      <PlainSelectInput
                        name="initiatedTimeZone"
                        options={data.timeZoneNames.map((tz) => ({
                          value: tz,
                          label: tz,
                        }))}
                      />
                    </div>
                  </div>
                </HorizontalField>
              </div>

              <div className="mt-3">
                <HorizontalSelectField
                  name="appealReason"
                  label="Appeal Reason"
                  options={appealReasonOptions}
                />
              </div>

              <div className="mt-3">
                <HorizontalTextAreaField
                  name="kqReason"
                  label="K/Q Reason"
                />
              </div>

              <div className="mt-3">
                <HorizontalField label="Late Processing">
                  <div className="flex items-center gap-2">
                    <SingleCheckboxInput name="lateProcessing" />
                    <label htmlFor="lateProcessing">Late Processing?</label>
                  </div>
                </HorizontalField>
              </div>

              <div className="mt-3">
                <HorizontalTextAreaField
                  name="verbalClinicalInfo"
                  label="Verbal Clinical Info"
                />
              </div>
              {/*
              <div className="mt-3">
                <HorizontalFileUploadField
                  name="fileUploadIds"
                  label="Upload supporting documents"
                />
              </div> */}
              <div className="flex items-center justify-center gap-3 px-2 py-4 mt-3">
                <Button type="button" onClick={onBack} size="lg">
                  <span className="mr-2">
                    <FAIcon icon="arrow-left" />
                  </span>
                  Back
                </Button>
                <Button
                  type="submit"
                  kind="primary"
                  color="teal"
                  size="lg"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Continue
                  <span className="ml-2">
                    <FAIcon icon="arrow-right" />
                  </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

function capitalize(str: string | undefined | null) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
