import { FC, ReactNode, forwardRef, MouseEvent } from "react";
import { Link, LinkProps } from "react-router-dom";

type ButtonType = "primary" | "secondary" | "tertiary";
type ButtonSize = "xs" | "sm" | "base" | "lg" | "xl";

const baseClassNames =
  "inline-flex items-center justify-center focus:outline-none transition ease-in-out duration-150";

function classNamesForType(btnType: ButtonType, btnColor: AppColor): string {
  switch (btnType) {
    case "primary":
      return "border " + primaryClassNamesForColor(btnColor);
    case "secondary":
      return (
        "border border-transparent " + secondaryClassNamesForColor(btnColor)
      );
    case "tertiary":
      return tertiaryClassNamesForColor(btnColor);
  }
}

function classNamesForSize(btnSize: ButtonSize): string {
  switch (btnSize) {
    case "xs":
      return "px-2.5 py-1.5 text-xs leading-4 font-medium rounded";
    case "sm":
      return "px-3 py-2 text-sm leading-4 font-medium rounded-md";
    case "base":
      return "px-4 py-2 text-sm leading-5 font-medium rounded-md";
    case "lg":
      return "px-4 py-2 text-base leading-6 font-medium rounded-md";
    case "xl":
      return "px-6 py-3 text-base leading-6 font-medium rounded-md";
  }
}

function primaryClassNamesForColor(btnColor: AppColor): string {
  switch (btnColor) {
    case "teal":
      return "border-transparent text-white bg-teal-600 hover:bg-teal-500 focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700";
    case "accent-green":
      return "border-transparent text-white bg-accent-green-600 hover:bg-accent-green-500 focus:border-accent-green-700 focus:shadow-outline-accent-green active:bg-accent-green-700";
    case "mint":
      return "border-transparent text-dark-teal-900 bg-mint-600 hover:bg-mint-500 focus:border-mint-700 focus:shadow-outline-mint active:bg-mint-700";
    case "dark-teal":
      return "border-transparent text-white bg-dark-teal-600 hover:bg-dark-teal-500 focus:border-dark-teal-700 focus:shadow-outline-dark-teal active:bg-dark-teal-700";
    case "red":
      return "border-transparent text-white bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red active:bg-red-700";
    case "orange":
      return "border-transparent text-white bg-orange-600 hover:bg-orange-500 focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700";
    case "yellow":
      return "border-transparent text-white bg-yellow-600 hover:bg-yellow-500 focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700";
    case "green":
      return "border-transparent text-white bg-green-600 hover:bg-green-500 focus:border-green-700 focus:shadow-outline-green active:bg-green-700";
    case "blue":
      return "border-transparent text-white bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700";
    case "indigo":
      return "border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700";
    case "purple":
      return "border-transparent text-white bg-purple-600 hover:bg-purple-500 focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700";
    case "pink":
      return "border-transparent text-white bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:shadow-outline-pink active:bg-pink-700";
    case "gray":
      return "border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50";
    case "gold":
      return "border-transparent text-white bg-gold-600 hover:bg-gold-500 focus:border-gold-700 focus:shadow-outline-yellow active:bg-gold-700";
    case "navy":
      return "border-transparent text-white bg-navy-600 hover:bg-navy-500 focus:border-navy-700 focus:shadow-outline-blue active:bg-navy-700";
  }
}

function secondaryClassNamesForColor(btnColor: AppColor): string {
  switch (btnColor) {
    case "teal":
      return "text-teal-700 bg-teal-100 hover:bg-teal-50 focus:border-teal-300 focus:shadow-outline-teal active:bg-teal-200";
    case "accent-green":
      return "text-accent-green-700 bg-accent-green-100 hover:bg-accent-green-50 focus:border-accent-green-300 focus:shadow-outline-accent-green active:bg-accent-green-200";
    case "mint":
      return "text-dark-teal-900 bg-mint-200 hover:bg-mint-200 hover:border-mint-400 border-transparent focus:border-mint-300 focus:shadow-outline-mint active:bg-mint-200";
    case "dark-teal":
      return "text-dark-teal-700 bg-dark-teal-100 hover:bg-dark-teal-50 focus:border-dark-teal-300 focus:shadow-outline-dark-teal active:bg-dark-teal-200";
    case "red":
      return "text-red-700 bg-red-100 hover:bg-red-50 focus:border-red-300 focus:shadow-outline-red active:bg-red-200";
    case "orange":
      return "text-orange-700 bg-orange-100 hover:bg-orange-50 focus:border-orange-300 focus:shadow-outline-orange active:bg-orange-200";
    case "yellow":
      return "text-yellow-700 bg-yellow-100 hover:bg-yellow-50 focus:border-yellow-300 focus:shadow-outline-yellow active:bg-yellow-200";
    case "green":
      return "text-green-700 bg-green-100 hover:bg-green-50 focus:border-green-300 focus:shadow-outline-green active:bg-green-200";
    case "blue":
      return "text-blue-700 bg-blue-100 hover:bg-blue-50 focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-200";
    case "indigo":
      return "text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200";
    case "purple":
      return "text-purple-700 bg-purple-100 hover:bg-purple-50 focus:border-purple-300 focus:shadow-outline-purple active:bg-purple-200";
    case "pink":
      return "text-pink-700 bg-pink-100 hover:bg-pink-50 focus:border-pink-300 focus:shadow-outline-pink active:bg-pink-200";
    case "gray":
      return "text-gray-700 bg-gray-100 hover:bg-gray-50 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-200";
    case "gold":
      return "text-gold-700 bg-gray-100 hover:bg-gray-50 focus:border-gold-500 focus:shadow-outline-amber active:bg-gray-200";
    case "navy":
      return "text-navy-700 bg-blue-100 hover:bg-blue-50 focus:border-navy-300 focus:shadow-outline-blue active:bg-blue-200";
  }
}

function tertiaryClassNamesForColor(btnColor: AppColor): string {
  switch (btnColor) {
    case "teal":
      return "border-teal-300 text-teal-700 bg-white hover:text-teal-500 hover:bg-teal-50 focus:border-teal-300 focus:shadow-outline-teal active:text-teal-800 active:bg-teal-50";
    case "mint":
      return "border-mint-300 text-mint-700 bg-white hover:text-mint-500 hover:bg-mint-50 focus:border-mint-300 focus:shadow-outline-mint active:text-mint-800 active:bg-mint-50";
    case "accent-green":
      return "border-accent-green-300 text-accent-green-700 bg-white hover:text-accent-green-500 hover:bg-accent-green-50 focus:border-accent-green-300 focus:shadow-outline-accent-green active:text-accent-green-800 active:bg-accent-green-50";
    case "dark-teal":
      return "border-dark-teal-300 text-dark-teal-700 bg-white hover:text-dark-teal-500 hover:bg-dark-teal-50 focus:border-dark-teal-300 focus:shadow-outline-dark-teal active:text-dark-teal-800 active:bg-dark-teal-50";
    case "red":
      return "border-red-300 text-red-700 bg-white hover:text-red-500 hover:bg-red-50 focus:border-red-300 focus:shadow-outline-red active:text-red-800 active:bg-red-50";
    case "orange":
      return "border-orange-300 text-orange-700 bg-white hover:text-orange-500 hover:bg-orange-50 focus:border-orange-300 focus:shadow-outline-orange active:text-orange-800 active:bg-orange-50";
    case "yellow":
      return "border-yellow-300 text-yellow-700 bg-white hover:text-yellow-500 hover:bg-yellow-50 focus:border-yellow-300 focus:shadow-outline-yellow active:text-yellow-800 active:bg-yellow-50";
    case "green":
      return "border-green-300 text-green-700 bg-white hover:text-green-500 hover:bg-green-50 focus:border-green-300 focus:shadow-outline-green active:text-green-800 active:bg-green-50";
    case "blue":
      return "border-blue-300 text-blue-700 bg-white hover:text-blue-500 hover:bg-blue-50 focus:border-blue-300 focus:shadow-outline-blue active:text-blue-800 active:bg-blue-50";
    case "indigo":
      return "border-indigo-300 text-indigo-700 bg-white hover:text-indigo-500 hover:bg-indigo-50 focus:border-indigo-300 focus:shadow-outline-indigo active:text-indigo-800 active:bg-indigo-50";
    case "purple":
      return "border-purple-300 text-purple-700 bg-white hover:text-purple-500 hover:bg-purple-50 focus:border-purple-300 focus:shadow-outline-purple active:text-purple-800 active:bg-purple-50";
    case "pink":
      return "border-pink-300 text-pink-700 bg-white hover:text-pink-500 hover:bg-pink-50 focus:border-pink-300 focus:shadow-outline-pink active:text-pink-800 active:bg-pink-50";
    case "gold":
    case "navy":
    case "gray":
      return "border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50";
  }
}

/**
 * LoadingSpinner.
 */

const LoadingSpinner: FC = () => {
  return (
    <svg
      className="animate-spin w-5 h-5 mr-3 -ml-1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

/**
 * Button.
 */

export interface ButtonProps {
  /** Button style prop */
  color?: AppColor;
  /** Button style prop */
  kind?: ButtonType;
  /** Button style prop */
  size?: ButtonSize;
  /** Button style prop */
  className?: string;
  /** Optional icon. */
  icon?: ReactNode;
  /** Icon only button (tweaks styling) */
  iconOnly?: boolean;
  /** Whether the button is disabled. */
  disabled?: boolean;
  /** Whether the button is loading (shows spinner). */
  isLoading?: boolean;
  /** The type of the button */
  type?: "button" | "submit";
  /** The callback to call when the button is clicked */
  onClick?(event: MouseEvent): void;
  children?: ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      type = "button",
      color = "gray",
      kind = "primary",
      size = "base",
      className,
      onClick,
      children,
      disabled,
      isLoading,
    } = props;

    const cn = [
      baseClassNames,
      classNamesForSize(size),
      classNamesForType(kind, color),
      className,
      disabled && "opacity-50 cursor-not-allowed",
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <button
        ref={ref}
        type={type}
        className={cn}
        onClick={onClick}
        disabled={disabled}
      >
        {isLoading ? <LoadingSpinner /> : null}
        {children}
      </button>
    );
  }
);

/**
 * ButtonLink.
 */

interface ButtonLinkProps extends LinkProps {
  /** Button style prop */
  color?: AppColor;
  /** Button style prop */
  kind?: ButtonType;
  /** Button style prop */
  size?: ButtonSize;
  /** Button style prop */
  className?: string;
  /** Optional icon. */
  icon?: ReactNode;
  /** Icon only button (tweaks styling) */
  iconOnly?: boolean;
  /** Whether the button is disabled. */
  disabled?: boolean;
  /** Whether the button is loading (shows spinner). */
  isLoading?: boolean;
}

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (props, ref) => {
    const {
      color = "gray",
      kind = "primary",
      size = "base",
      className,
      disabled,
      ...rest
    } = props;

    const cn = [
      baseClassNames,
      classNamesForSize(size),
      classNamesForType(kind, color),
      className,
      disabled && "opacity-50 cursor-not-allowed",
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <Link ref={ref} className={cn} aria-disabled={disabled} {...rest}></Link>
    );
  }
);
