import { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Button } from "components/Button";
import { ScreenTitle } from "context/ScreenTitle";
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { hMmA, mmDdYy } from "utils/dateFormatters";
import { AddTATRulesModal } from "./AddTATRulesModal";
import { FilterPanel, FilterModel, defaultValue as defaultFilter } from "./FilterPanel";

const LIST_TAT_RULES = gql`
  query ListTatRules($first: Int, $after: UUID4, $filter: ListTatRulesFilter) {
    tatRules(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        stateAbbreviation
        state {
          id
          abbreviation
        }
        healthPlan {
          id
          name
        }
        planType
        patientPlanCode
        companyId
        lineOfBusiness
        urgency
        delegation
        tat
        tatUnit
        tatSla
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  tatRules: Paginated<TatRule>;
}

interface TatRule {
  id: string;
  stateAbbreviation: string | null;
  state: {
    id: string;
    abbreviation: string;
  } | null;
  healthPlan: {
    id: string;
    name: string;
  };
  planType: string | null;
  patientPlanCode: string | null;
  companyId: string | null;
  lineOfBusiness: string | null;
  urgency: string | null;
  delegation: string | null;
  tat: number;
  tatUnit: string;
  tatSla: number;
  insertedAt: string;
  updatedAt: string;
}

type ActiveModal = { type: "ADD_RULES" };

interface TATRulesScreenProps { }

export const TATRulesScreen: FC<TATRulesScreenProps> = (props) => {
  const [filter, setFilter] = useState<FilterModel>(defaultFilter);

  const { data, loading, error, refetch } = useQuery<Data>(LIST_TAT_RULES,
    { fetchPolicy: "network-only", variables: { filter } }
  );

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  return (
    <>
      <ScreenTitle title="TAT Rules" />
      <AddTATRulesModal
        isOpen={activeModal?.type === "ADD_RULES"}
        onClose={() => setActiveModal(null)}
        onSuccess={() => {
          refetch();
          setActiveModal(null);
        }}
      />
      <div className="_TATRulesScreen sm:rounded-md ">
        <div className="sm:p-6 px-4 py-6 bg-white">
          <div className="flex items-center justify-between pb-4">
            <div>
              <h2
                id="tat_rules_heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                TAT Rules
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Manage system TAT rules
              </p>
            </div>
            <div className="flex pt-6 pl-6">
              <Button
                type="button"
                color="teal"
                kind="primary"
                onClick={() => setActiveModal({ type: "ADD_RULES" })}
              >
                Add Rules
              </Button>
            </div>
          </div>

          <div className="py-4">
            <FilterPanel value={filter} onChange={setFilter} isLoading={loading} />
          </div>

          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.tatRules ? (
            <p>Failed to load.</p>
          ) : data.tatRules.items.length === 0 ? (
            <NoResults icon="list-alt" text="No matching TAT rules" />
          ) : (
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH>Health Plan</TH>
                    <TH>State</TH>
                    <TH>Plan Type</TH>
                    <TH>Patient Plan Code</TH>
                    <TH>Company ID</TH>
                    <TH>Line of Business</TH>
                    <TH>Urgency</TH>
                    <TH>Delegation Level</TH>
                    <TH>TAT</TH>
                    <TH>SLA</TH>
                    <TH>Updated</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.tatRules.items.map((r) => (
                    <tr key={r.id}>
                      <TD className="px-3 py-1">{r.healthPlan.name}</TD>
                      <TD className="px-3 py-1 text-lg">{r.stateAbbreviation || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.planType || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.patientPlanCode || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.companyId || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.lineOfBusiness || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.urgency || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.delegation || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.tat} {r.tatUnit}</TD>
                      <TD className="px-3 py-1 text-lg">{r.tatSla}%</TD>

                      <TD className="px-3 py-1">{mmDdYy(r.updatedAt)} {hMmA(r.insertedAt)}</TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  )
};

function Wildcard() {
  return (
    <span className="font-mono text-xl italic text-purple-400">*</span>
  )
}
