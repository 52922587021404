import { FC, useCallback } from "react";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { useAnalytics } from "screens/InternalPortal/AnalyticsScreen/AnalyticsContext";
import { mmDdYyyy } from "utils/dateFormatters";
import { Button } from "components/Button";
import { DatePickerField } from "components/formik/DatePickerField";
import { RadioGroupField } from "components/formik/RadioGroupField";
import { TextField } from "components/formik/TextField";
import { localDateToISO } from "utils/localDateToISO";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const CREATE_REPORT_EXPORT = gql`
  mutation CreateReportExport($input: ReportExportInput!) {
    createReportExport(input: $input) {
      errors {
        key
        message
      }
      reportExport {
        id
      }
    }
  }
`;

const encryptionMethodOptions = ["AES", "PGP"].map((t) => ({
  value: t,
  label: t,
}));

const validationSchema = Yup.object().shape({
  dateRange: Yup.object().shape({
    start: Yup.string().required("Start date is required"),
    finish: Yup.string()
      .required("End date is required")
      .test(
        "is-after-start",
        "End date must be after start date",
        function (value) {
          const { start } = this.parent;
          if (!start || !value) return true; // Skip validation if either date is missing
          return new Date(value) >= new Date(start);
        }
      ),
  }),
  encryptionMethod: Yup.string().required("Encryption method is required"),
  encryptionPassword: Yup.string().when("encryptionMethod", {
    is: "AES",
    then: (schema) =>
      schema
        .required("Password is required for AES encryption")
        .min(8, "Password must be at least 8 characters"),
    otherwise: (schema) => schema,
  }),
});

/**
 * DownloadFailedUmAssignmentsReportForm.
 */

interface DownloadFailedUmAssignmentsReportFormProps {
  onSuccess(): void;
}

export const DownloadFailedUmAssignmentsReportForm: FC<DownloadFailedUmAssignmentsReportFormProps> = (
  props
) => {
  const { onSuccess } = props;
  const { timeRange } = useAnalytics();

  const [createExport] = useMutation(CREATE_REPORT_EXPORT);

  const onSubmit = useCallback(
    (values, formikActions) => {
      const input = {
        exports: ["failed_um_assignments"],
        windowStart: localDateToISO(values.dateRange.start),
        windowFinish: localDateToISO(values.dateRange.finish),
        encryptionMethod: values.encryptionMethod,
        password: values.encryptionPassword,
      };

      return createExport({
        variables: { input },
      }).then((resp) => {
        if (resp.data?.createReportExport.reportExport) {
          // it worked
          toast.success("Report started! You'll receive an email once complete.", {});
          return onSuccess();
        }
      });
    },
    [onSuccess, createExport]
  );

  return (
    <div className="DownloadFailedUmAssignmentsReportForm">
      <Formik
        initialValues={{
          dateRange: {
            start: mmDdYyyy(timeRange.start),
            finish: mmDdYyyy(timeRange.finish),
          },
          encryptionMethod: "AES",
          encryptionPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="grid grid-cols-2 gap-4">
              <DatePickerField
                name="dateRange.start"
                label="Start Date"
                icon={["far", "calendar-alt"]}
              />

              <DatePickerField
                name="dateRange.finish"
                label="End Date"
                icon={["far", "calendar-alt"]}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <RadioGroupField
                name="encryptionMethod"
                label="Encryption Method"
                options={encryptionMethodOptions}
                inline
              />

              {values.encryptionMethod === "AES" ? (
                <TextField
                  type="password"
                  icon="lock"
                  name="encryptionPassword"
                  label="Encryption Password"
                  placeholder="••••••••••"
                />
              ) : null}
            </div>

            <div className="mt-6 text-center">
              <Button type="submit" kind="primary" color="teal" isLoading={isSubmitting}>
                Create Report Export
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
