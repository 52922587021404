import { FC } from "react";
import ArchiveIcon from '@heroicons/react/24/solid/ArchiveBoxIcon';
import ChatAltIcon from '@heroicons/react/24/solid/ChatBubbleOvalLeftEllipsisIcon';
import HandRaisedIcon from '@heroicons/react/24/solid/HandRaisedIcon';
import UserPlusIcon from '@heroicons/react/24/solid/UserPlusIcon';
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon';
import DocumentTextIcon from '@heroicons/react/24/solid/DocumentTextIcon';
import PhoneIcon from '@heroicons/react/24/solid/PhoneIcon';
import PhoneXMarkIcon from '@heroicons/react/24/solid/PhoneXMarkIcon';

import { distanceInWords, hMmA, mmDdYyyy } from "utils/dateFormatters";
import { UserAvatar } from "components/UserAvatar";
import { Tooltip } from "components/Tooltip";

type AppealRequestEventName = keyof AppealRequestEventDataModels;

export interface AppealRequestEventModel<T extends AppealRequestEventName = AppealRequestEventName> {
  id: string;
  type: T;
  insertedAt: string;
  rollupId: string;
  agent: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  data: AppealRequestEventDataModels[T];
}

type AppealRequestEventDataModels = {
  case_pended: CasePendedEventDataModel;
  case_unpended: CaseUnpendedEventDataModel;
  comment_added: CommentAddedEventDataModel;
  request_closed: RequestClosedEventDataModel;
  md_assigned: MdAssignedEventDataModel;
  md_assignment_failed: MdAssignmentFailedEventDataModel;
  intake_questions_saved: IntakeQuestionsSavedEventDataModel;
  verbal_notification_log_saved: VerbalNotificationLogSavedEventDataModel;
  verbal_notification_log_failed: VerbalNotificationLogFailedEventDataModel;
}

interface BaseAppealRequestEventDataModel {
  eventType: AppealRequestEventName;
}

interface CasePendedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "CasePendedEventData";
  eventName: "case_pended";
  reason: string;
}

interface CaseUnpendedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "CaseUnpendedEventData";
  eventName: "case_unpended";
  trigger: string;
}

interface CommentAddedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "CommentAddedEventData";
  eventName: "comment_added";
  comment: string;
  visibility: "everyone" | "internal_only";
}

interface RequestClosedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "BaseAppealRequestEventData";
  eventName: "request_closed";
}

interface MdAssignedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "MdAssignedEventData";
  eventName: "md_assigned";
  providerId: string;
  providerName: string;
}

interface MdAssignmentFailedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "MdAssignmentFailedEventData";
  eventName: "md_assignment_failed";
  providerId: string | null;
  providerName: string | null;
  searchFilter: any;
  reason: string | null;
}

interface IntakeQuestionsSavedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "IntakeQuestionsSavedEventData";
  eventName: "intake_questions_saved";
  qualifiers: any;
  response: any;
}

interface VerbalNotificationLogSavedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "VerbalNotificationLogSavedEventData";
  eventName: "verbal_notification_log_saved";
  journalNote: string;
  historyMessage: string;
}

interface VerbalNotificationLogFailedEventDataModel extends BaseAppealRequestEventDataModel {
  __typename: "VerbalNotificationLogFailedEventData";
  eventName: "verbal_notification_log_failed";
  journalNote: string;
  historyMessage: string;
  error: string;
}

const components: Record<AppealRequestEventName, FC<{ event: any }>> = {
  case_pended: CasePendedEvent,
  case_unpended: CaseUnpendedEvent,
  comment_added: CommentAddedEvent,
  request_closed: RequestClosedEvent,
  md_assigned: MdAssignedEvent,
  md_assignment_failed: MdAssignmentFailedEvent,
  intake_questions_saved: IntakeQuestionsSavedEvent,
  verbal_notification_log_saved: VerbalNotificationLogSavedEvent,
  verbal_notification_log_failed: VerbalNotificationLogFailedEvent
}

const HoverTime: FC<{ timestamp: string, className?: string }> = props => {
  const { timestamp, className } = props;
  const cn = ["cursor-pointer", className].filter(Boolean).join(" ");
  return (
    <span className={cn}>
      <Tooltip tip={<p>{mmDdYyyy(timestamp)} at {hMmA(timestamp)}</p>}>
        {distanceInWords(timestamp)} ago
      </Tooltip>
    </span>
  );
}

/**
 * AppealRequestEvent.
 */

interface AppealRequestEventProps {
  showLine?: boolean;
  appealRequestEvent: AppealRequestEventModel;
};

export const AppealRequestEvent: FC<AppealRequestEventProps> = props => {
  const { showLine = false, appealRequestEvent } = props;
  const Component = components[appealRequestEvent.type];

  return (
    <li>
      <div className="relative pb-8">
        {showLine ? (
          <span
            className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          ></span>
        ) : null}
        <Component event={appealRequestEvent} />
      </div>
    </li>
  );
};

function CasePendedEvent(props: {
  event: AppealRequestEventModel<"case_pended">;
}) {
  const { event } = props;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <HandRaisedIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            Case moved to pending. Reason: {event.data.reason}.
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function CaseUnpendedEvent(props: {
  event: AppealRequestEventModel<"case_unpended">;
}) {
  const { event } = props;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <HandRaisedIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            Case moved out of pending. Trigger: {event.data.trigger}.
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function CommentAddedEvent(props: {
  event: AppealRequestEventModel<"comment_added">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div className="relative">
        <UserAvatar
          size={40}
          firstName={firstName!}
          lastName={lastName!}
        />

        <span
          className="absolute bg-white rounded"
          style={{
            bottom: -2,
            right: -4,
            padding: 1,
          }}
        >
          <ChatAltIcon className="w-5 h-5 text-gray-400" />
        </span>
      </div>
      <div className="_CommentBubble rounded-xl flex-1 min-w-0 p-3 border shadow-md">
        <div>
          <div className="text-sm leading-tight">
            <span className="font-medium text-gray-900">
              {firstName} {lastName}
            </span>
          </div>
          <p className="mt-0.5 text-xs leading-tight text-gray-500">
            Commented <HoverTime timestamp={event.insertedAt} />
          </p>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          <p className="whitespace-pre-wrap">{event.data.comment}</p>
        </div>
      </div>
    </div>
  );
}

function RequestClosedEvent(props: {
  event: AppealRequestEventModel<"request_closed">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <ArchiveIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-gray-900">
              {firstName} {lastName}
            </span>{" "}
            closed this post-decision request.
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function MdAssignedEvent(props: {
  event: AppealRequestEventModel<"md_assigned">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <UserPlusIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-gray-900">
              {firstName} {lastName}
            </span>{" "}
            assigned an MD reviewer: {event.data.providerName}
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function MdAssignmentFailedEvent(props: {
  event: AppealRequestEventModel<"md_assignment_failed">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <ExclamationTriangleIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-gray-900">
              {firstName} {lastName}
            </span>{" "}
            attempted to assign an MD reviewer {event.data.providerName ? `(${event.data.providerName})` : ""}, but assignment failed. {event.data.reason ? `Reason: ${event.data.reason}` : null}
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function IntakeQuestionsSavedEvent(props: {
  event: AppealRequestEventModel<"intake_questions_saved">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <DocumentTextIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-gray-900">
              {firstName} {lastName}
            </span>{" "}
            processed intake questions.
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function VerbalNotificationLogSavedEvent(props: {
  event: AppealRequestEventModel<"verbal_notification_log_saved">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <PhoneIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-gray-900">
              {firstName} {lastName}
            </span>{" "}
            logged verbal notification. History Message: {event.data.historyMessage}.
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function VerbalNotificationLogFailedEvent(props: {
  event: AppealRequestEventModel<"verbal_notification_log_failed">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full">
            <PhoneXMarkIcon className="w-5 h-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-sm text-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-gray-900">
              {firstName} {lastName}
            </span>{" "}
            attempted to log verbal notification, but it failed. History Message: {event.data.historyMessage}. Error: {event.data.error}.
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}
