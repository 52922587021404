"use client";

import { useState } from "react";
import { Button } from "components/Button";
import { toast } from "react-hot-toast";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Spinner } from "components/Spinner";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { useRouteMatch } from "react-router-dom";

const ReportExportQuery = gql`
  query ReportExport($id: UUID4!) {
    reportExport(id: $id) {
      id
      status
    }
  }
`;

interface Data {
  reportExport: {
    id: string;
    status: string;
  }
}

const DownloadReportExportMutation = gql`
  mutation DownloadReportExport($id: UUID4!) {
    downloadReportExport(id: $id) {
      error
      url
    }
  }
`;

interface MutationData {
  downloadReportExport: {
    error: string;
    url: string;
  }
}

interface RouteParams {
  id: string;
}

export function ReportExportDownloadScreen() {
  const { params: { id: reportExportId } } = useRouteMatch<RouteParams>();

  // State
  const [isDownloading, setIsDownloading] = useState(false);

  // Query
  const { data, loading, error, refetch } = useQuery<Data>(ReportExportQuery, { variables: { id: reportExportId } });

  // Mutation
  const [downloadReportExportMut] = useMutation<MutationData>(DownloadReportExportMutation)

  async function handleDownload() {
    setIsDownloading(true);
    try {
      const { data } = await downloadReportExportMut({ variables: { id: reportExportId } });

      if (data?.downloadReportExport?.error) {
        toast.error(data.downloadReportExport.error);
        return;
      }

      if (data?.downloadReportExport?.url) {
        window.location.href = data.downloadReportExport.url;
      }

    } catch (err) {
      toast.error("Something went wrong.");
      console.error("Failed to download data export", err);
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh] text-center px-4">
      <div className="text-logo-blue p-3 mb-6 bg-white rounded-full shadow-md">
        <ArrowDownIcon className="w-12 h-12 mx-auto" />
      </div>
      <h1 className="mb-4 text-2xl font-bold">Download Report Export</h1>

      {
        loading ? (
          <Spinner />
        ) : error || !data?.reportExport ? (
          <>
            <p className="text-red-500">Failed to load</p>
            <Button onClick={() => refetch()} className="mt-4">Retry</Button>
          </>
        ) : ["pending", "in_progress"].includes(data.reportExport.status) ? (
          <p className="text-gray-800">Your report export is still being generated. Please wait and refresh the page...</p>
        ) : data.reportExport.status === "failed" ? (
          <p className="text-red-500">Your report export failed to generate. Please try again.</p>
        ) : (
          <>
            <p className="text-muted-foreground max-w-md mb-8">
              Your report export is ready. Click the button below to download.
            </p>
            <Button
              size="lg"
              onClick={handleDownload}
              disabled={isDownloading}
              className="min-w-[200px]"
            >
              {isDownloading ? (
                <span className="mr-2"><Spinner /></span>
              ) : (
                <ArrowDownIcon className="w-5 h-5 mr-2" />
              )}
              {isDownloading ? "Downloading..." : "Download Report Export"}
            </Button>
          </>
        )
      }
    </div>
  );
}
