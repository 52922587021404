import { FC, useState } from "react";
import { gql, useQuery } from '@apollo/client';
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";
import { SelectInput } from "components/formik/SelectField";
import { Button } from "components/Button";
import { TextInput } from "components/formik/TextField";
import { SkillSearchMode } from "./AssignMDDrawer";
import { SkillOption, SkillOptionModel } from "components/SkillOption";
import { ToggleSwitch } from "components/ToggleSwitch";
import { ToggleSwitchInput } from "components/formik/ToggleSwitchField";

const PROVIDER_AVAILABILITY_FILTER_DATA = gql`
  query ProviderAvailabilityFilterData($modalityId: String!) {
    modalitySkills: skills(filter: { modalityId: $modalityId }) {
      id
      name
      iexName
      caseSystem
    }
    allSkills: skills {
      id
      name
      iexName
      caseSystem
    }
    states {
      id
      name
    }
  }
`;

interface Data {
  modalitySkills: SkillModel[];
  allSkills: SkillModel[];
  states: { id: string, name: string }[];
}

interface SkillModel {
  id: string;
  name: string;
  iexName: string;
  caseSystem: string;
}

export interface ProviderAvailabilityFilter {
  includePediatric?: null | boolean;
  exclusivelyPediatric?: null | boolean
  includeSmes?: null | boolean;
  providerDomainIds?: null | string[];
  excludeProviderDomainIds?: null | string[];
  icd10?: null | string;
  shiftBuffer?: null | number;
  shiftStartBuffer?: null | number;
  shiftEndBuffer?: null | number;
  skillIds: string[];
  licensedInStateId?: null | string;
  licensedInStateIds?: null | string[];
  searchTerm?: null | string;
  externalSystemName: string;
}

interface FilterPanelProps {
  skillSearchModes: SkillSearchMode[];
  modalityId: string;
  defaultValue: ProviderAvailabilityFilter;
  value: ProviderAvailabilityFilter;
  onChange(filter: ProviderAvailabilityFilter): void;
  isLoading?: boolean;
};

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { skillSearchModes, modalityId, defaultValue, value, onChange, isLoading = false } = props;
  const { data, loading } = useQuery<Data>(PROVIDER_AVAILABILITY_FILTER_DATA, { variables: { modalityId } })
  const [showAllSkills, setShowAllSkills] = useState(false);

  const stateOptions = data?.states.map(asOption) || [];

  return (
    <div className="_FilterPanel">
      <FilterForm<ProviderAvailabilityFilter>
        defaultValue={defaultValue}
        value={value}
        onChange={(values: ProviderAvailabilityFilter) => onChange(values as any)}
      >
        <div className="flex items-end gap-2">
          {/* <FilterField htmlFor="searchTerm" icon="search" label="Search Term">
          <div className="w-48">
            <TextInput
              name="searchTerm"
              placeholder="Search..."
              icon="search"
            />
          </div>
        </FilterField> */}

          {skillSearchModes.includes("skills") ? (
            <FilterField
              htmlFor="skillIds"
              icon="filter"
              label="Skills"
            >
              <div className="w-64">
                <SelectInput<SkillOptionModel, true>
                  className="z-10"
                  isLoading={loading}
                  isClearable
                  name="skillIds"
                  options={(showAllSkills ? data?.allSkills : data?.modalitySkills) || []}
                  components={{ Option: SkillOption }}
                  getOptionValue={(option: SkillOptionModel) => option.id}
                  getOptionLabel={(option: SkillOptionModel) => option.name}
                  isMulti
                />
                <div className="bg-gray-50 flex items-center justify-center gap-2 px-2 py-1 mt-1 text-xs rounded">
                  <ToggleSwitch id="allSkills" size={20} checked={showAllSkills} onChange={() => setShowAllSkills(!showAllSkills)} /> Show All Skills
                </div>
              </div>
            </FilterField>
          ) : null}

          <div>
            {skillSearchModes.includes("icd10") ? (
              <FilterField
                htmlFor="icd10"
                icon="filter"
                label="ICD-10"
              >
                <div className="w-48">
                  <TextInput
                    name="icd10"
                    placeholder="ICD-10"
                  />
                  <div className="bg-gray-50 flex items-center justify-center gap-2 px-2 py-1 mt-1 text-xs rounded">
                    <ToggleSwitchInput name="includePediatric" toggleSwitchProps={{ size: 20 }} /> Include Pediatric
                  </div>
                  <div className="bg-gray-50 flex items-center justify-center gap-2 px-2 py-1 mt-1 text-xs rounded">
                    <ToggleSwitchInput name="exclusivelyPediatric" toggleSwitchProps={{ size: 20 }} /> Only Pediatric
                  </div>
                </div>
              </FilterField>
            ) : null}
            <div className="bg-gray-50 flex items-center justify-center gap-2 px-2 py-1 mt-1 text-xs rounded">
              <ToggleSwitchInput name="includeSmes" toggleSwitchProps={{ size: 20 }} /> Include SMEs
            </div>
          </div>


          <FilterField
            htmlFor="licensedInStateIds"
            icon="filter"
            label="States Licensed"
          >
            <div className="w-48">
              <SelectInput
                isLoading={loading}
                isClearable
                name="licensedInStateIds"
                options={stateOptions}
                isMulti
              />
            </div>
          </FilterField>

          <FilterField
            htmlFor="searchTerm"
            icon="search"
            label="Search"
          >
            <div className="w-48">
              <TextInput name="searchTerm" placeholder="Search Term" />
            </div>
          </FilterField>

          <FilterField>
            <Button
              type="submit"
              color="teal"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Search
            </Button>
          </FilterField>
        </div>
      </FilterForm>
    </div>
  );
};

function asOption<T extends { id: string; name: string }>(item: T): Option {
  return {
    value: item.id,
    label: item.name,
  };
}
