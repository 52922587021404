import { FC } from "react";
import { gql, useMutation } from "@apollo/client";
import { Modal, ModalHeader } from "components/Modal";
import { Form, Formik } from "formik";
import { SelectField } from "components/formik/SelectField";
import { Button } from "components/Button";
import toast from "react-hot-toast";

const UPDATE_AGENT_ROLE = gql`
  mutation UpdateAgentRole($id: UUID4!, $role: String!) {
    updateAgentRole(id: $id, role: $role) {
      errors {
        key
        message
      }
      agent {
        id
        role
      }
    }
  }
`;

interface UpdateRoleModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
  agentId: string | void;
}

interface FormValues {
  role: string;
}

const initialValues: FormValues = {
  role: "staff"
};

export const UpdateRoleModal: FC<UpdateRoleModalProps> = (props) => {
  const { isOpen, onClose, onSuccess, agentId } = props;

  const [updateRole, { loading }] = useMutation(UPDATE_AGENT_ROLE);

  const handleSubmit = async (values: FormValues) => {
    const result = await updateRole({
      variables: {
        id: agentId,
        role: values.role
      }
    });

    if (!result.errors) {
      onSuccess();
      toast.success("Role updated.");
      return onClose();
    }
  };


  return (
    <Modal
      className="_UpdateRoleModal"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        icon="user-edit"
        title="Update User Role"
        onClose={onClose}
      />
      <div className="p-4">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Form>
            <SelectField
              name="role"
              label="Role"
              options={[
                { value: "staff", label: "Staff" },
                { value: "admin", label: "Admin" }
              ]}
            />
            <div className="flex justify-end mt-4">
              <Button
                type="submit"
                color="teal"
                isLoading={loading}
              >
                Update Role
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};
