import { FC } from "react";

const classNameMap: Record<AppColor, string> = {
  gray: "bg-gray-100 border-gray-200 text-gray-600",
  red: "bg-red-100 border-red-200 text-red-600",
  orange: "bg-orange-100 border-orange-200 text-orange-600",
  yellow: "bg-yellow-100 border-yellow-200 text-yellow-600",
  green: "bg-green-100 border-green-200 text-green-600",
  blue: "bg-blue-100 border-blue-200 text-blue-600",
  indigo: "bg-indigo-100 border-indigo-200 text-indigo-600",
  purple: "bg-purple-100 border-purple-200 text-purple-600",
  pink: "bg-pink-100 border-pink-200 text-pink-600",
  gold: "bg-gold-100 border-gold-200 text-gold-600",
  navy: "bg-navy-100 border-navy-200 text-navy-600",
  teal: "bg-teal-100 border-teal-200 text-teal-600",
  mint: "bg-mint-100 border-accent-green-200 text-accent-green-600",
  "accent-green": "bg-accent-green-100 border-accent-green-200 text-accent-green-600",
  "dark-teal": "bg-dark-teal-100 border-dark-teal-200 text-dark-teal-600",
};

interface BadgeProps {
  color: AppColor;
  className?: string;
}

export const Badge: FC<BadgeProps> = (props) => {
  const { color, children, className = "" } = props;

  return (
    <p
      className={`${classNameMap[color]} border capitalize font-semibold inline-block leading-none ml-2 px-2 py-1 rounded-full text-xs ${className}`}
    >
      {children}
    </p>
  );
};
