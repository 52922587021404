import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { toast } from "react-hot-toast";
import { Button } from "components/Button";
import { Panel, Provider } from "./AvailablePanelsSearch";
import { FAIcon } from "components/FAIcon";
import { VerticalField } from "components/FieldStructure";
import { hhMmA, mmDdYyyy } from "utils/dateFormatters";
import { HorizontalNumberField } from "components/formik/NumberField";
import { TextAreaField } from "components/formik/TextAreaField";

const CREATE_PANEL_APPOINTMENT = gql`
  mutation CreatePanelAppointment($appealRequestId: UUID4!, $input: PanelAppointmentInput!) {
    createPanelAppointment(appealRequestId: $appealRequestId, input: $input) {
      errors {
        key
        message
      }
      panelAppointment {
        id
      }
    }
  }
`;

interface MutationData {
  createPanelAppointment: {
    errors?: InputError[];
    panelAppointment?: {
      id: string
    };
  };
}

interface MutationVariables {
  appealRequestId: string;
  input: FormValues
}

interface FormValues {
  startTime: string;
  duration: number;
  providerIds: string[];
  additionalNotes: string;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    startTime: Yup.string().required("Required"),
    duration: Yup.number().required("Required"),
    providerIds: Yup.array().of(Yup.string()).required("Required"),
    additionalNotes: Yup.string()
  })
  .required();

interface BookPanelAppointmentFormProps {
  appealRequestId: string;
  panel: Panel;
  initialValues: FormValues;
  onSuccess: any;
};

export const BookPanelAppointmentForm: FC<BookPanelAppointmentFormProps> = props => {
  const { appealRequestId, panel, onSuccess, initialValues } = props;
  const [createPanelAppointment] = useMutation<MutationData, MutationVariables>(CREATE_PANEL_APPOINTMENT);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await createPanelAppointment({ variables: { appealRequestId, input: values } });

        if (data?.createPanelAppointment.errors) {
          setStatus({ errors: data.createPanelAppointment.errors });
        } else if (data?.createPanelAppointment.panelAppointment) {
          // it worked...
          toast.success("Panel Appointment Booked");
          setSubmitting(false);
          return onSuccess();
        }
      } catch (e) {
        console.error(e)
        setStatus({ errors: [{ key: "", message: "Something went wrong" }] });
      }
      setSubmitting(false);
    },
    [createPanelAppointment, onSuccess, appealRequestId]
  );

  return (
    <div className="_BookPanelAppointmentForm">
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <VerticalField label="Appointment" className="mt-3">
              <p className="text-lg text-center"><FAIcon icon="calendar-alt" className="mr-2 text-gray-500" />{mmDdYyyy(values.startTime)}</p>
              <p className="pt-1 text-lg text-center"><FAIcon icon={["far", "clock"]} className="mr-2 text-gray-500" />{hhMmA(values.startTime)}</p>
              <HorizontalNumberField
                name="duration"
                label="Duration (minutes)"
                min={10}
                max={120}
              />
            </VerticalField>
            <VerticalField label="Providers" className="mt-5">
              {panel.providers.map(provider => (
                <ProviderCard key={provider.id} provider={provider} />
              ))}
            </VerticalField>
            <div className="mt-5">
              <TextAreaField
                name="additionalNotes"
                label="Additional Notes"
              />
            </div>
            <div className="flex items-center justify-center py-4 mt-3">
              <Button
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                kind="primary"
                color="teal"
              >
                Save Appointment
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

function ProviderCard({ provider }: { provider: Provider }) {
  return (
    <div className="flex items-center">
      <div className="text-accent-green-700 rounded-xl flex items-center justify-center w-8 h-8 p-0.5 text-sm bg-accent-green-100 shadow-inner">
        <FAIcon icon="user-md" />
      </div>
      <div className="flex-grow pt-1 pl-3">
        <p className="text-xl leading-relaxed">{provider.nameWithAppellation}</p>
        <p className="text-xs leading-tight text-gray-600">{provider.primarySpecialty ? provider.primarySpecialty + " - " : null}{provider.email}</p>
      </div>
    </div>
  );
}
