import React, { FC } from "react";
import { usePopperTooltip, PopperOptions } from "react-popper-tooltip";
import { Placement } from "@popperjs/core";
import { useMeasure } from "react-use";
import { PopUp } from "components/Animations";
import 'react-popper-tooltip/dist/styles.css';
import "./Tooltip.css";

const colorClassNames: Record<AppColor, string> = {
  blue: "bg-blue-100 text-blue-700",
  gold: "bg-gold-100 text-gold-700",
  gray: "bg-gray-100 text-gray-700",
  green: "bg-green-100 text-green-700",
  indigo: "bg-indigo-100 text-indigo-700",
  navy: "bg-navy-100 text-navy-700",
  orange: "bg-orange-100 text-orange-700",
  pink: "bg-pink-100 text-pink-700",
  purple: "bg-purple-100 text-purple-700",
  red: "bg-red-100 text-red-700",
  yellow: "bg-yellow-100 text-yellow-700",
  teal: "bg-teal-100 text-teal-700",
  mint: "bg-mint-100 text-mint-700",
  "accent-green": "bg-accent-green-100 text-accent-green-700",
  "dark-teal": "bg-dark-teal-100 text-dark-teal-700",
};

interface TooltipProps {
  tip: React.ReactNode;
  hideArrow?: boolean;
  color?: AppColor;
  style?: React.CSSProperties;
  onMouseLeave?(event: React.SyntheticEvent): void;
  placement?: PopperOptions["placement"]
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const {
    children,
    tip: tooltip,
    hideArrow = false,
    onMouseLeave,
    placement = "top",
    color = "indigo"
  } = props;

  const cn = colorClassNames[color];

  const [containerRef, { height, width }] = useMeasure();

  // NB: This keeps the tip of the tooltip triangle from being in the center of the trigger element.
  //     I want a "top" tooltip to actually sit on top of the trigger element.
  const offset = offsetForPlacementAndSize(placement, { height, width });

  const {
    getArrowProps,
    getTooltipProps,
    setTriggerRef,
    setTooltipRef,
    visible
  } = usePopperTooltip({ placement, offset });

  return (
    <div className="inline-block" ref={containerRef as any}>
      {visible && (
        <PopUp
          show
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `tooltip-container border ${color} ${cn}`,
            onMouseLeave,
          })}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                className: `tooltip-arrow ${color}`,
                "data-placement": placement,
              })}
            />
          )}
          {tooltip}
        </PopUp>
      )}

      <span ref={setTriggerRef}>
        {children}
      </span>
    </div>
  );
};

interface Size {
  width: number;
  height: number;
}

function offsetForPlacementAndSize(
  placement: Placement,
  size: Size
): [number, number] {
  const { width, height } = size;

  switch (placement) {
    case "top":
      return [0, height / 2];
    case "left":
    case "right":
      return [width / 2, 0];
    default:
      return [0, 0];
  }
}
