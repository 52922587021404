import { FC, useState } from "react";
import { gql, useQuery } from '@apollo/client';
import { CaseProviderAvailabilityFilter } from "../AssignMDDrawer";
import { Spinner } from "components/Spinner";
import { NoResults } from "components/NoResults";
import { JsonDebugger } from "components/JsonDebugger";
import { distanceInWords, hhMmA, mmDdYyyy } from "utils/dateFormatters";
import { Button } from "components/Button";
import { FAIcon } from "components/FAIcon";
import { removeVoidKeys } from "utils/removeVoidKeys";
import { FilterPanel, ProviderAvailabilityFilter } from "../AssignMDDrawer/FilterPanel";
import { InputNumber } from "components/formik/NumberField";
import { HorizontalField } from "components/FieldStructure";
import { BookPanelAppointmentForm } from './BookPanelAppointmentForm';

const DEFAULT_PANEL_SIZE = 2;

const PANEL_AVAILABILITY = gql`
  query PanelAvailabilities($filter: ProviderAvailabilityFilterInput!) {
    panelAvailability(filter: $filter) {
      items {
        soonestAvailability
        panel {
          id
          providers {
            id
            firstName
            lastName
            nameWithAppellation
            primarySpecialty
            email
            domainId
            assignedAppealRequestCount
          }
        }
      }
    }
  }
`;

interface Data {
  panelAvailability: {
    items: PanelAvailability[];
  }
}

export interface PanelAvailability {
  soonestAvailability: string;
  panel: Panel;
}

export interface Panel {
  id: string;
  providers: Provider[];
}

export interface Provider {
  id: string;
  firstName: string;
  lastName: string;
  nameWithAppellation: string;
  primarySpecialty: string;
  email: string;
  domainId: string;
  assignedAppealRequestCount: number;
}

export interface PanelAvailabilityFilter extends ProviderAvailabilityFilter {
  panelSize: number;
}

/**
 * AvailablePanelsSearch.
 */

interface AvailablePanelsSearchProps {
  appealRequestId: string;
  modalityId: string;
  caseFilterData: CaseProviderAvailabilityFilter;
  onBookSuccess(): void;
};

export const AvailablePanelsSearch: FC<AvailablePanelsSearchProps> = props => {
  const { appealRequestId, modalityId, caseFilterData, onBookSuccess } = props;

  const defaultFilter = toFilter(caseFilterData);
  const [filter, setFilter] = useState<ProviderAvailabilityFilter>(defaultFilter)
  const [panelSize, setPanelSize] = useState(DEFAULT_PANEL_SIZE);

  function excludeProvider(domainId: string) {
    const domainIds = filter.excludeProviderDomainIds?.includes(domainId) ? filter.excludeProviderDomainIds : [...(filter.excludeProviderDomainIds || []), domainId];
    setFilter({ ...filter, excludeProviderDomainIds: domainIds });
  }

  function unexcludeProvider(domainId: string) {
    const domainIds = filter.excludeProviderDomainIds?.filter(did => did !== domainId) || [];
    setFilter({ ...filter, excludeProviderDomainIds: domainIds });
  }

  const [data, setData] = useState<Data | null>(null);
  const { loading, error } = useQuery<Data>(PANEL_AVAILABILITY, { variables: { filter: { ...removeVoidKeys(filter), panelSize } }, onCompleted: setData });

  const [selectedPanelAvailability, setSelectedPanelAvailability] = useState<PanelAvailability | null>(null);

  return (
    <div className="_AvailablePanelsSearch">
      <FilterPanel
        skillSearchModes={caseFilterData.skillSearchModes}
        modalityId={modalityId}
        defaultValue={defaultFilter}
        value={filter}
        onChange={(newFilter) => setFilter({ ...newFilter, excludeProviderDomainIds: filter.excludeProviderDomainIds })}
      />

      <div className="flex items-center justify-center">
        <div style={{ width: 290 }}>
          <HorizontalField label="Panel Size">
            <InputNumber
              value={panelSize}
              onChange={setPanelSize}
              inputProps={{ disabled: !!selectedPanelAvailability || loading }}
              min={2}
              max={3}
            />
          </HorizontalField>
        </div>

        {filter.excludeProviderDomainIds && filter.excludeProviderDomainIds.length > 0 ? (
          <div className="flex justify-center">
            <div className="bg-yellow-50 px-2 py-1 text-sm text-center text-yellow-700 border border-yellow-300 rounded">
              <p className="flex items-center">
                <FAIcon icon="exclamation-circle" className="mr-2" />
                {
                  filter.excludeProviderDomainIds.length === 1 ? (
                    `Excluding 1 provider: ${filter.excludeProviderDomainIds[0]}`
                  ) : (
                    <div>
                      Excluding {filter.excludeProviderDomainIds.length} providers:
                      <ul>
                        {filter.excludeProviderDomainIds.map(domainId => (
                          <li key={domainId} className="gap-x-2 flex items-center list-disc">
                            {domainId} <Button kind="tertiary" color="red" size="sm" onClick={() => unexcludeProvider(domainId)}>
                              <FAIcon icon="times" />
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                }
              </p>
            </div>
          </div>
        ) : null}
      </div>

      {loading ? (
        <div className="p-6 text-center">
          <Spinner />
        </div>
      ) : error || !data?.panelAvailability ? (
        <p>Failed to load.</p>
      ) : data.panelAvailability.items.length === 0 ? (
        <NoResults icon="calendar-alt" text="No matching provider availabilities" />
      ) : (
        <>
          {selectedPanelAvailability ? (
            <div className="max-w-sm p-4 mx-auto mt-4 bg-white border rounded-lg shadow-md">
              <Button
                kind="tertiary"
                color="purple"
                onClick={() => setSelectedPanelAvailability(null)}
              >
                <FAIcon icon="arrow-left" className="mr-2" />Back to Results
              </Button>
              <BookPanelAppointmentForm
                appealRequestId={appealRequestId}
                panel={selectedPanelAvailability.panel}
                initialValues={{
                  startTime: selectedPanelAvailability.soonestAvailability,
                  duration: 30,
                  providerIds: selectedPanelAvailability.panel.providers.map(p => p.id),
                  additionalNotes: ""
                }}
                onSuccess={onBookSuccess}
              />
            </div>
          ) : (
            <div>
              {data.panelAvailability.items.map(pa => (
                <div key={pa.panel.id} className="flex px-4 py-3 mt-3 bg-white border rounded shadow">
                  <div className="flex-grow">
                    <p>Available together in <span className="font-bold">{distanceInWords(pa.soonestAvailability)} on {mmDdYyyy(pa.soonestAvailability)} at {hhMmA(pa.soonestAvailability)}</span></p>

                    <div className="_panel flex items-center justify-around p-6">
                      {pa.panel.providers.map(provider => (
                        <div key={provider.id}>
                          <p>Current Case Load: <span className="font-bold">{provider.assignedAppealRequestCount}</span></p>
                          <div className="flex items-center">
                            <div className="text-accent-green-700 rounded-xl flex items-center justify-center w-8 h-8 p-0.5 text-sm bg-accent-green-100 shadow-inner">
                              <FAIcon icon="user-md" />
                            </div>
                            <div className="flex-grow pt-1 pl-3">
                              <p className="text-xl leading-relaxed">{provider.firstName} {provider.lastName}</p>
                              <p className="text-xs leading-tight text-gray-600">{provider.primarySpecialty ? provider.primarySpecialty + " - " : null}{provider.email}</p>
                              <Button kind="tertiary" color="red" size="sm" onClick={() => excludeProvider(provider.domainId)}>Exclude</Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col items-center p-4 border-l">
                    <p className="text-sm"><FAIcon icon="calendar-alt" className="mr-2 text-gray-500" />{mmDdYyyy(pa.soonestAvailability)}</p>
                    <p className="pt-1 text-sm"><FAIcon icon={["far", "clock"]} className="mr-2 text-gray-500" />{hhMmA(pa.soonestAvailability)}</p>
                    <Button
                      kind="secondary"
                      color="mint"
                      className="mt-3"
                      onClick={() => setSelectedPanelAvailability(pa)}
                    >
                      Book Panel
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}

        </>
      )
      }
      <div className="mt-12">
        <JsonDebugger data={data} />
      </div>
    </div >
  );
};

function toFilter(caseData: CaseProviderAvailabilityFilter): ProviderAvailabilityFilter {
  return {
    providerDomainIds: caseData.providerDomainIds,
    excludeProviderDomainIds: caseData.excludeProviderDomainIds,
    includeSmes: true,
    icd10: caseData.skillSearchModes.includes("icd10") ? caseData.icd10 : null,
    shiftBuffer: caseData.shiftBuffer,
    shiftStartBuffer: caseData.shiftStartBuffer,
    shiftEndBuffer: caseData.shiftEndBuffer,
    skillIds: caseData.skillSearchModes.includes("skills") ? (caseData.skills || []).map(s => s.id) : [],
    licensedInStateIds: caseData.licensedInStates.map(s => s.id),
    externalSystemName: caseData.externalSystemName,
    searchTerm: ""
  }
}
