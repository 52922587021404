import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { DownloadFailedUmAssignmentsReportForm } from "./DownloadFailedUmAssignmentsReportForm";

interface DownloadFailedUmAssignmentsReportModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const DownloadFailedUmAssignmentsReportModal: FC<DownloadFailedUmAssignmentsReportModalProps> = (
  props
) => {
  const { isOpen, onClose } = props;

  return (
    <Modal
      className="_DownloadFailedUmAssignmentsReportModal"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        icon="file-download"
        title="Export Failed UM Assignments Report"
        onClose={onClose}
      />
      <div className="p-4">
        <DownloadFailedUmAssignmentsReportForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
