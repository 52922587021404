import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button } from "components/Button";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "components/Spinner";
import { FAIcon } from "components/FAIcon";

const APPEAL_REQUEST = gql`
  query GetAppealRequest($id: UUID4!) {
    appealRequest(id: $id) {
      id
      urgency
    }
  }
`;

interface Data {
  appealRequest: {
    id: string;
    urgency: string;
  };
}

interface Variables {
  id: string;
}

// const CONFIRM_APPEAL_REQUEST = gql`
//   mutation ConfirmAppealRequest($appealRequestId: UUID4!) {
//     confirmAppealRequest(appealRequestId: $appealRequestId) {
//       errors {
//         key
//         message
//       }
//       appealRequest {
//         id
//       }
//     }
//   }
// `;

// interface MutationData {
//   confirmAppealRequest: {
//     errors?: InputError[];
//     appealRequest?: {
//       id: string;
//     };
//   };
// }

// interface MutationVariables {
//   appealRequestId: string;
// }

interface FormValues { }

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({})
  .required();

/**
 * ConfirmationForm.
 */

interface ConfirmationFormProps {
  appealRequestId: string;
  onBack(): void;
  onSuccess(): void;
}

export const ConfirmationForm: FC<ConfirmationFormProps> = (props) => {
  const { appealRequestId, onBack, onSuccess } = props;

  const { data, loading, error } = useQuery<Data, Variables>(APPEAL_REQUEST, {
    variables: { id: appealRequestId },
    fetchPolicy: "network-only",
  });

  // const [confirmAppealRequest] = useMutation<MutationData, MutationVariables>(
  //   CONFIRM_APPEAL_REQUEST
  // );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      // console.log({ appealRequestId, values });
      return onSuccess();
    },
    [onSuccess]
  );

  return (
    <div className="_ConfirmationForm">
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !data?.appealRequest ? (
        <p>Failed to load</p>
      ) : (
        <Formik<FormValues>
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, status }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <div className="flex items-center justify-center gap-3 px-2 py-4 mt-3">
                <Button type="button" onClick={onBack} size="lg">
                  <span className="mr-2">
                    <FAIcon icon="arrow-left" />
                  </span>
                  Back
                </Button>
                <Button
                  type="submit"
                  kind="primary"
                  color="teal"
                  size="lg"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Submit
                  <span className="ml-2">
                    <FAIcon icon="arrow-right" />
                  </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
